import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { formatDateInMonthDateYear } from "../../../utils/common/Format";
import TextareaField from "../../ui/TextArea";
import { isNotEmptyString } from "../../../utils/common/Validation";
import { ErrorMessages } from "../../../constants/Labels";
import InputField from "../../ui/Input";

interface ServeynProps {
    setShowServeyPopup?: any

}

const UserServeyPopup = ({ setShowServeyPopup }: ServeynProps) => {
    const [todayDate, setToday] = useState(new Date())
    const [question1, setquestion1] = useState("");
    const [question2, setquestion2] = useState("");
    const [question3, setquestion3] = useState("");
    const [question4, setquestion4] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});



    const newErrors: { [key: string]: string } = {};

    const submitHandler = (e: any) => {
        e.preventDefault();
        if (!isNotEmptyString(question1)) {
            newErrors.question1 = "Please ask atleast one question.";
        }
        // if (!isNotEmptyString(question3)) {
        //     newErrors.question3 = "select one option.";
        // }
        if (Object.keys(newErrors).length === 0) {
            setShowServeyPopup(false)
        }
    }

    // Form handlechange
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        switch (name) {
            case "question1":
                setquestion1(value);
                break;
            case "question2":
                setquestion2(value);
                break;
            case "question3":
                setquestion3(value);
                break;
            case "question4":
                setquestion4(value);
                break;
            default:
                break;
        }
    }

    return (
        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className=" br-8 bg-white  shadow-xxl border justify-content-center w-auto">
                    <div className='p-3'>
                        <div className="d-flex justify-content-between">
                            <h6 className="fw-bold" >{formatDateInMonthDateYear(todayDate)}</h6>
                            <RxCross2 onClick={() => setShowServeyPopup(false)} size={20} className="cursor-pointer" />
                        </div>
                        <div>
                            <h3 className="fw-bold mb-2" > Servey</h3>
                        </div>
                        <div className="border-white p-2 border-2" style={{ backgroundColor: '#8080801f' }}>
                            <h6 className="fw-bold ">Thank you for taking part </h6>
                            <h6 className="text-dark">Please complete this document to help us improve future sessions. </h6>
                        </div>
                        <form onSubmit={submitHandler}>
                            <div className="">
                                <div className="form-group mb-2">
                                    <TextareaField
                                        name="question1"
                                        label="1. Question text goes here"
                                        componentName=''
                                        value={question1}
                                        onChange={handleChange}
                                        placeholder=""
                                        error={errors.question1}
                                        rows={3}
                                    />
                                </div>
                                <div className="form-group mb-2">
                                    <TextareaField
                                        name="question2"
                                        label="2. Question text goes here"
                                        componentName=''
                                        value={question2}
                                        onChange={handleChange}
                                        placeholder=""
                                        error={errors.question2}
                                        rows={3}
                                    />
                                </div>
                                <div className="form-group mb-2">
                                    <label className="fw-bold mb-1">4. Question text goes here.</label><span className="ms-1 ">1 = Strongly Agree, 5 = Strongly Disagree.</span>
                                    <div className="d-flex justify-content-between">
                                        {[1, 2, 3, 4, 5].map((value) => (
                                            <label key={value}>
                                                <input
                                                    type="radio"
                                                    name="question2"
                                                    value={value}
                                                    checked={question2 === String(value)}
                                                    onChange={handleChange}
                                                />
                                                {value}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <label className="fw-bold ">5. Question text goes here.</label> <span className="ms-1 fw-lighter"> Select one option. </span>
                                    <div>
                                        {['Multiple choice answer 1', 'Multiple choice answer 2', 'Multiple choice answer 3'].map((answer, index) => (
                                            <label key={index} style={{ display: 'block' }}>
                                                <input
                                                    type="radio"
                                                    name="question3"
                                                    value={answer}
                                                    checked={question3 === answer}
                                                    onChange={handleChange}
                                                    className="me-2 mt-1"
                                                />
                                                {answer}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end align-items-end mt-3 pt-2'>
                                    <button className='btn w-25 font-size-14 p-y-12 me-2' onClick={() => setShowServeyPopup(false)}>Cancel</button>
                                    <button className='btn btn-dark ' type="submit">Send to users</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserServeyPopup;