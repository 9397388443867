import {toast } from 'react-toastify';


export const showToastSuccess = (message: any) => {
    toast.success(message);
};

export const showToastError = (message: any) => {
    toast.error(message);
};

export const showToastWarning = (message: any) => {
    toast.warning(message);
};
