import activeImg from '../assets/images/active.png'
import inactiveImg from '../assets/images/inactive-png.png'
import loader from '../assets/images/loader.gif'


export const fetchImages = {
    "activeImg": activeImg , 
    "inactiveImg": inactiveImg , 
    "loader" : loader

    
}