import { createSlice, isAction, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { act } from "react";
import { getLoginUserID } from "../../services/AuthService";

interface StoreBucket {
  isLoggedIn: boolean;
  userDetails: any;
  showSuccespopup: boolean;
  userName : any
  loggedInUserNameUpdate?:any
}
const loggedInUserName = getLoginUserID()

const initialState: StoreBucket = {
  isLoggedIn: false,
  userDetails: {},
  showSuccespopup: false,
  userName : loggedInUserName?.userName ,
  loggedInUserNameUpdate:false

};

const authSlice = createSlice({
  name: "storeBucket",
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setShowSuccesPopup: (state, action) => {
      state.showSuccespopup = action.payload;
    },
    setloggedInUserNameUpdate: (state, action) => {
      state.loggedInUserNameUpdate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoggedIn, setUserDetails, setShowSuccesPopup ,setloggedInUserNameUpdate } = authSlice.actions;

export default authSlice.reducer;
