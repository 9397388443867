export const AppEnvironment = {
    AppName: "Autiscia",
    OtpInputs: 5,
    PreferenceTypeId: 1,
    CurrentDate: new Date().toISOString().split("T")[0],
    AnimationTimeOut: 5000
}

const Window = (window as any).config;


