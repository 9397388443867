export const ErrorMessages = {
    EmailRequired: "Please Enter Email Address",
    InvalidEmail: "Please Enter a valid Email Address",
    passwordRequired: "Please Enter Password",
    NewPassword: "Please Enter Confirm Password ",
    NewPasswordUpdate: "Please Enter New Password ",
    PasswordStrength6Characters: "Password should be at least 8 characters",
    // StrongPassword: "Password should contains Min length of 6 char, at least one uppercase & lowercase,one number & one special character",
    StrongPassword: "Password must be atleast 8 char include an uppercase,lowercase letter,number,special character. ",
    PasswordStrengthPopup: "Password should follow mentioned conditions",
    PasswordMaxLengthExceeded: 'Password cannot exceed 20 characters',
    FirstNameRequired: "Please Enter First Name",
    NameRequired: "Please Enter Name",
    LastNameRequired: "Please Enter Last Name",
    CurrentPasswordRequired: "Please Enter Current Password",
    ReEnterPasswordRequired: "Please Enter Confirm Password",
    PasswordUnmatchError: "Passwords doesn't match",
    SamePasswordError: "New password cannot be the same as your current password",
    UpdatePassword: "Current password is incorrect",
    WrongPassword: "Current password is wrong",
    TitleRequired: "Title is required",
    DecriptionRequired: "Description is required",
    FilesRequired: "Select atleast one icon",
    TagsRequired: "Select atleast one tag",
    SomethingWrong: "Something Went Wrong",
    WebLink:'Web Link is required',
    TopicsRequired:"Select atleast one topic",
    ReviewRequired:'Review is required',
    deleteReason:'Please Enter the reason for deleting the user',
    SensitiveContent:'Please check sensitive content',
    otpError:'OTP is required'
    




}

export const DefaultMessages = {
    ApiError: "There is an issue in the Server Connectivity. Please try again after some time",
    LoginSuccess: "Login Successful",
    ForgotPassword: "Please check your email and follow the instructions to reset your password",


}