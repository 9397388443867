import React from 'react';

const QuillToolbar: React.FC = () => (
    <div id="toolbar">
        <select className="ql-header" defaultValue="" onChange={e => e.persist()}>
            <option value="1" />
            <option value="2" />
            <option value="3" />
            <option value="" />
        </select>
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-link" />
        {/* <button className="ql-image" /> */}
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
    </div>
);
// image
const formats = [
    'header', 'bold', 'italic', 'underline', 'link', 'list'
];

function imageHandler(this: any) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
        const file = input.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const base64Image = e.target?.result;
                if (base64Image) {
                    const quill = this.quill;
                    const range = quill.getSelection();
                    quill.insertEmbed(range.index, 'image', base64Image);
                }
            };
            reader.readAsDataURL(file);
        }
    };
}

const modules = {
    toolbar: {
        container: "#toolbar",
        handlers: {
            image: imageHandler
        }
    }
};

export { QuillToolbar as default, formats, modules };
