import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";

interface Props {
    images: {
        CategoryID: string;
        Filename: string;
        Filepath: string;
    }[];
    onClose: () => void;
    handleSubmit: (selectedImages: any) => void;
    categories: { value: string; label: string }[];
    selectedImages: any[]; // Update type to match the structure of selectedImages
}

export default function IconsPopup({ images, onClose, handleSubmit, categories, selectedImages }: Props) {
    const defaultCategoryId = 'ba1a3a24-950b-48a8-90f1-a51e7072deef';
    const [localSelectedImages, setLocalSelectedImages] = useState<any[]>(selectedImages); // Local state to manage image selection
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>(defaultCategoryId);
    const [selectedCategoryImages, setSelectedCategoryImages] = useState<any[]>([]);

    // Effect to filter images based on selectedCategoryId
    useEffect(() => {
        const filteredImages = images.filter(image => image.CategoryID === selectedCategoryId);
        setSelectedCategoryImages(filteredImages);
    }, [selectedCategoryId, images]);

    // Handle image selection
    const handleImageSelect = (image: any) => {
        const updatedSelection = localSelectedImages.some((item: any) => item.Filepath === image.Filepath  || item.name == image.Filepath )
            ? localSelectedImages.filter((item: any) => item.Filepath !== image.Filepath && item.name !== image.Filepath  )
            : [...localSelectedImages, image];

        updatedSelection.map((item:any)=> {
           
            item['RFilePath'] = item?.Filepath && item.Filepath.startsWith('http')
            ? item.Filepath
            : require(`../../../assets/AutisticaIcons${item?.Filepath || ''}`);

            item['IsNew'] = item?.Filepath && item.Filepath.startsWith('http')
            ? 0
            : 1
          
        })

        setLocalSelectedImages(updatedSelection); // Update local selection state
    };

    // Handle category change
    const handleCategoryChange = (e: any) => {
        setSelectedCategoryId(e.value);
    };

    // Handle form submission
    const handleIconSubmit = () => {
        handleSubmit(localSelectedImages); 
        setLocalSelectedImages([]); // Clear the local selection after submission
        onClose(); // Close the popup
    };

    return (
        <div className="position-fixed w-100 start-0 top-0 h-100 z-[99]">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className="rounded-md bg-white shadow-xxl border justify-content-center w-[800px]">
                    <div className='py-4 px-3'>
                        <div className="d-flex justify-content-between mb-4 position-relative smart-dropdown p-dropdown-w-240 ps-12-p-dropdown-label">
                            <span className="mt-0 text-lg font-semibold">Selected Icons: {localSelectedImages?.length}</span>

                            <Dropdown
                                value={selectedCategoryId}
                                options={categories}
                                onChange={handleCategoryChange}
                                placeholder="Filter"
                                optionLabel="label"
                                optionValue="value"
                            />
                        </div>

                        <div className="h-[400px] overflow-y-auto overflow-x-hidden mb-4">
                            <div className="row overflow-x-hidden me-1 !ml-[-4px]">
                                {selectedCategoryImages.map(image => (
                                    <div key={image.Filepath} className="col-md-2 mb-2 px-1">
                                        <div className="border rounded-[4px] hover:shadow-lg">
                                            <div  onClick={() => handleImageSelect(image)}
                                                className={`position-relative w-full h-[70px] d-flex align-items-center justify-content-center cursor-pointer ${localSelectedImages.some((item: any) => item.Filepath === image.Filepath || item?.name == image?.Filepath) ? 'image-selected' : ''}`}
                                            >
                                                <div className="form-check position-absolute top-0 end-0 z-[99]">
                                                    <input
                                                        type="checkbox"
                                                        checked={localSelectedImages.some((item: any) => item.Filepath === image.Filepath || item?.name == image?.Filepath) }
                                                        onChange={() => handleImageSelect(image)}
                                                        className="form-check-input mt-[2px] !ml-[0px] !mr-[2px] cursor-pointer"
                                                    />
                                                </div>
                                                <img src={require(`../../../assets/AutisticaIcons${image.Filepath}`)} alt={image.Filename} className="w-[30px] h-[30px] object-contain" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary-outline px-4 text-white !leading-[18px] me-1" type="button" onClick={onClose}>Cancel</button>
                            <button className="btn btn-primary px-4 text-white !leading-[18px]" type="button" onClick={handleIconSubmit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
