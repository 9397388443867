import React from 'react';

interface TextareaFieldProps {
  name: string;
  value: string;
  placeholder: string;
  label: string;
  onChange: any
  error?: string;
  maxLenght?: number
  componentName?: string,
  AddContextPopUp?:boolean,
  rows?:number,
  onKeyDown?:any
}

const TextareaField: React.FC<TextareaFieldProps> = ({
  name,
  value,
  placeholder,
  label,
  onChange,
  error,
  maxLenght,
  rows,
  onKeyDown

}) => {
  return (
    <div className="form-group position-relative">
      <label className="text-dark mb-0">{label}</label>
      <textarea
        // className='w-100 form-control mh-122'
        className={"form-control mt-0 "}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLenght}
        rows={rows}
        onKeyDown={onKeyDown}

      />
      {error && <label className="text-danger">{error}</label>}
    </div>
  );
};

export default TextareaField;
