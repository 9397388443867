import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";

interface Props {
  label: string;
  onChange: (value: string) => void;
  value?:any
  
}

const SearchInput: React.FC<Props> = ({ label, onChange ,value}) => {
  const [search, setSearch] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
    onChange(value);
  };

  return (
    <div className="position-relative mb-0">
      <input
        className="form-control pe-34 text-truncket"
        onChange={handleChange}
        placeholder={label}
        name="search"
        autoComplete="off"
        value={value}
      />
      <span className="position-absolute top-50 end-0 text-gray me-2 fs-18 translate-y-[-50%]">
        <CiSearch size={20} />
      </span>
    </div>
  );
};

export default SearchInput;
