import React from "react";
import { MdOutlineDashboard, MdOutlineLogout } from "react-icons/md";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { IoBarChartOutline } from "react-icons/io5";
import { PiChatCenteredDotsLight } from "react-icons/pi";
import { RiTeamLine } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import { RiUserSettingsLine } from "react-icons/ri";
import { clearSession } from "../../services/AuthService";





export default function SideMenu() {
  const navigate = useNavigate()
  const logout = () => {
    clearSession();
    localStorage.clear()
    navigate('../signin');
  }
  return (
    <aside className="side-menu bg-white z-10 p-[12px] w-[210px] vh-calc-110 shadow-sm	 rounded-md position-fixed top-[94px] ml-[12px] ">
      <nav className="pt-[6px]">
        <ul className="menu-list p-0 m-0 h-calc-100vh-minus-152">
          <li className="mb-3">
            <NavLink
              to="/home"
              className={({ isActive }) => (isActive ? "rounded active text-decoration-none d-flex align-items-center p-2" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <MdOutlineDashboard size={25} className="text-dark" />
              </div>
              <div className="h-[25px] d-flex align-items-center">
              <div className="text-dark d-flex align-items-center leading-4 h-100 pt-1">Dashboard</div>
              </div>
            </NavLink>
          </li>
          {/* <li className="mb-3">
            <NavLink
              to="/metrics"
              className={({ isActive }) => (isActive ? "active text-decoration-none d-flex align-items-center" : "text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-1 d-flex align-items-center">
                <IoBarChartOutline size={22} />
              </div>
              <div >Metrics</div>
            </NavLink>
          </li> */}
          {/* <li className="mb-3">
            <NavLink
              to="/moderation"
              className={({ isActive }) => (isActive ? "active text-decoration-none d-flex align-items-center" : " text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-1 d-flex align-items-center">
                <PiChatCenteredDotsLight size={22} />
              </div>
              <div>Moderation</div>
            </NavLink>
          </li> */}
          <li className="mb-3">
            <NavLink
              to="/resources"
              className={({ isActive }) => (isActive ? "rounded p-2 active text-decoration-none d-flex align-items-center" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <RiTeamLine size={25} className="text-dark"/>
              </div>
              <div className="h-[25px] d-flex align-items-center">
              <div className="text-dark d-flex align-items-center leading-4 h-100 pt-1">Resources</div>
              </div>
              
            </NavLink>
          </li>
          {/* <li className="mb-3">
            <NavLink
              to="/settings"
              className={({ isActive }) => (isActive ? "active text-decoration-none d-flex align-items-center" : "text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-1 d-flex align-items-center">
                <IoSettingsOutline size={22} />
              </div>
              <div >Settings</div>
            </NavLink>
          </li> */}
          <li className="mb-3">
            <NavLink
              to="/userManagement"
              className={({ isActive }) => (isActive ? "rounded p-2 active text-decoration-none d-flex align-items-center" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            > 
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <RiUserSettingsLine size={22} className="text-dark"/>
              </div>
              <div className="h-[25px] d-flex align-items-center">
              <div className="text-dark d-flex align-items-center leading-4 h-100 pt-1"> User Management</div>
              </div>
              
            </NavLink>
          </li>
          {/* <li className="mb-0">
            <NavLink
              to="/contribute"
              className={({ isActive }) => (isActive ? "active text-decoration-none d-flex align-items-center" : "text-decoration-none d-flex align-items-center")}
            > <div className="w-[25px] h-[25px] flex-25 me-1 d-flex align-items-center">
                <RiUserSettingsLine size={22} />
              </div>
              <div >Contribute</div>
            </NavLink>
          </li> */}

        </ul>
      </nav>

      {/* <div
        className="d-flex align-item-center  py-1 user-details br-4  cursor-pointer" 
        onClick={() => logout()}>
        <div className="w-[25px] h-[25px] flex-25 me-1 d-flex align-items-center">
          <MdOutlineLogout size={22} className="text-secondary" />
        </div>
        <span className="d-flex align-items-center lh-30 ">
          Log Out
        </span>
      </div> */}
    </aside>
  );
}
