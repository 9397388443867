import React from "react";
import { showToastSuccess } from "../../utils/common/ToastMessages";


interface PopupProps {
    selectedItem?: any
    setshowTipDetails?: any
}

export default function ViewContributeTip({ selectedItem, setshowTipDetails }: PopupProps) {
    const approveTip = () => {
        setshowTipDetails(false)
        showToastSuccess("Tip approved succesfully")
    }
    return (

        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]  ">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className=" br-8 bg-white  shadow-xxl border justify-content-center w-25">
                    <div className='p-4 rounded'>
                        <div className="d-flex justify-content-center mb-2">
                            <h4 className="fw-bold">Selected Tip Details</h4>
                        </div>
                        <div className="mb-2">
                            <h6 className="me-3 fw-bold">Title:</h6>
                            <span>{selectedItem.resourcesTitle}</span>
                        </div>
                        <div className="mb-2">
                            <h6 className="me-3 fw-bold" >Date:</h6>
                            <span>{selectedItem.date}</span>
                        </div>
                        <div className="mb-2">
                            <h6 className="me-3 fw-bold">QI Score:</h6>
                            <span>{selectedItem.qiScore}</span>
                        </div>
                        <div className="mb-2">
                            <h6 className="me-3 fw-bold">Description:</h6>
                            <span>{selectedItem.resourcesDescription}</span>
                        </div>
                        <div className="mb-2">
                            <h6 className="me-3 fw-bold">Up Votes:</h6>
                            <span>{selectedItem.upVotes}</span>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button
                                className="btn btn-outline-dark me-2"
                                type="button"
                                onClick={() => setshowTipDetails(false)}>
                                Edit
                            </button>
                            <button
                                className="btn btn-dark me-2"
                                type="button"
                                onClick={approveTip}>
                                Approve
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}