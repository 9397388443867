export const handleClickOutside = (ref: any, callback: any) => {
    const handleClick = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            callback();
        }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
        document.removeEventListener('click', handleClick, true);
    };
};


