import React from 'react';

const Maintenance: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div>
        <h1 style={{textAlign:"center"}}>Under Maintenance</h1>
        <p>We are currently undergoing maintenance. Please check back later.</p>
      </div>
    </div>
  );
};

export default Maintenance;
