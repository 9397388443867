import axios from "axios";
import { httpService } from "./api/Api";
import { getAuthTOken } from "./AuthService";
import { id } from "date-fns/locale";
const token = getAuthTOken();

// To Add resource
export const CreateResource = (obj: any) => {
    return httpService.postFile('Resource/ResourceUpload', obj);
}
export const getResources = (obj: any) => {
    return httpService.post('Resource/GetResourceList', obj);
}

export const deleteReource = (obj: any) => {
    return httpService.post('Resource/DeleteResource', obj);
}
export const getLookUPData = () => {
    return httpService.get('Generic/GetLookupData');
}
export const getResourceByID = (id:any) => {
    return httpService.get('Resource/GetResourcesById'+'/' + id);
}
// To update the resource
export const UpdateResourceById = (obj: any) => {
    return httpService.postFile('Resource/UpdateResource', obj);
}
export const deleteReourceFile = (obj: any) => {
    return httpService.post('Resource/DeleteResourceLinkById', obj);
}