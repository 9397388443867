import React, { useEffect, useRef, useState } from "react";
import { BiSolidUpvote, BiUpArrowAlt } from "react-icons/bi";
import { GrResources } from "react-icons/gr";
import { PiUsersThree } from "react-icons/pi";
import { TbMessages } from "react-icons/tb";
import { Chart } from 'primereact/chart';
import { HiMiniArrowTrendingUp } from "react-icons/hi2";



export default function ResourceMetrics() {
    // Donought values
    const [chartData] = useState({
        labels: ['user Engagement', 'DAU', 'Bounce Rate'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ]
            }]
    });
    // Donought values
    const [lightOptions] = useState({
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true, // This will use point style for the legend
                    pointStyle: 'circle', // Set point style to circle
                    color: '#495057'
                }
            }
        }
    });
    // Graphs values 
    const [basicData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'First Dataset',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                borderColor: '#42A5F5',
                tension: .4
            },
            {
                label: 'Second Dataset',
                data: [28, 48, 40, 19, 86, 27, 90],
                fill: false,
                borderColor: '#FFA726',
                tension: .4
            }
        ]
    });
    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
    // Static object for trending toics
    const data = [
        { category: "mental health", value: 200 },
        { category: "diagnosis", value: 300 },
        { category: "awareness", value: 100 },
        { category: "nutrition", value: 250 },
        { category: "sleep", value: 150 }
    ];




    return (
        <div>
            <div className="d-flex  mt-3">
                <div className="resourceCard" >
                    <div className="d-flex justify-content-between">
                        <h5 className="fw-bolder">1000</h5>
                        <span><PiUsersThree size={20} /></span>
                    </div>
                    <span className="mt-4 fw-bold"> Reached to resource</span>
                </div>
                <div className="resourceCard ms-5">
                    <div className="d-flex justify-content-between">
                        <h5 className="fw-bolder">2000</h5>
                        <span><BiSolidUpvote size={20} /></span>
                    </div>
                    <span className="mt-4 fw-bold"> Number of Upvotes</span>
                </div>
                <div className="resourceCard ms-5">
                    <div className="d-flex justify-content-between">
                        <h5 className="fw-bolder">80</h5>
                        <span><TbMessages size={20} /></span>
                    </div>
                    <span className="mt-4 fw-bold"> Number of Flags</span>
                </div>
                <div className=" resourceCard ms-5">
                    <div className="d-flex justify-content-between">
                        <h5 className="fw-bolder">500</h5>
                        <span><GrResources size={20} /></span>
                    </div>
                    <span className="mt-4 fw-bold "> Number of Resources</span>
                </div>
            </div>
            <div className=" mt-3">
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div >
                                <Chart type="line" data={basicData} options={basicOptions} style={{ height: '295px', width: '800px' }} />
                            </div>
                        </div>

                    </div>
                    <div className="col-md-4  card justify-content-end "   >
                        <h5 className="fw-bold d-flex justify-content-center">User Engagement</h5>
                        <div style={{ position: 'relative', width: '60%', margin: '0 auto' }}>
                            <Chart type="doughnut" data={chartData} options={lightOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="d-flex justify-content-between mb-2">
                                <h5 className="fw-bold">Trending Topics</h5>
                                <HiMiniArrowTrendingUp className="fw-bold " size={20} />
                            </div>
                            {data && data.map((item: any, index: any) => (
                                <div key={index} className="d-flex justify-content-between">
                                    <h6 className=""> {item.category}</h6>
                                    <p className="d-flex justify-content-between"> <BiUpArrowAlt /> {item.value}</p>
                                </div>
                            ))}
                            <div className="d-flex justify-content-between">
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card">
                            <div className="d-flex justify-content-between mb-2">
                                <h5 className="fw-bold">Trending Topics</h5>
                                <HiMiniArrowTrendingUp className="fw-bold " size={20} />
                            </div>
                            {data && data.map((item: any, index: any) => (
                                <div key={index} className="d-flex justify-content-between">
                                    <h6 className=""> {item.category}</h6>
                                    <p className="d-flex justify-content-between"> <BiUpArrowAlt /> {item.value}</p>
                                </div>
                            ))}
                            <div className="d-flex justify-content-between">


                            </div>
                        </div>

                    </div>
                </div>



            </div>

        </div>

    )

}
