import React, { useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

export default function Metrics() {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [options, setOptions] = useState<string[]>(['Option 1', 'Option 2', 'Option 3']);
    const [inputValue, setInputValue] = useState('');

    const handleAddNewItem = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault();  // Prevent default behavior
            if (inputValue && !options.includes(inputValue)) {
                setSelectedItems([...selectedItems, inputValue]);
                setInputValue('');
            }
        }
    };

    const handleChange = (e:any) => {
        setSelectedItems(e.value);
    };
    const headerTemplate = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleAddNewItem}
                    placeholder="Type and press enter to add new topic"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };
    return (
        <div className="App" >
        <MultiSelect 
                value={selectedItems} 
                options={options.map((option) => ({ label: option, value: option }))} 
                onChange={handleChange}
                filter 
                display="chip" 
                panelHeaderTemplate={headerTemplate}
                style={{width:'50%'}}
                placeholder="select topics"
            />
    </div>
    );

}