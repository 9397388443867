import React from 'react';
import { AppEnvironment } from '../../constants/Global';

const useOtpInput = () => {
    const otpFields: React.RefObject<HTMLInputElement>[] = Array.from({ length: AppEnvironment.OtpInputs }, () => React.createRef());

    const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>, otpFields: React.RefObject<HTMLInputElement>[]) => {
        const input = e.target;
        if (/^\d*$/.test(input.value)) {
            if (input.value && index < otpFields.length - 1) {
                otpFields[index + 1].current?.focus();
            }
        } else {
            input.value = ''; // Clear the input if it's not numeric
        }

        const otpValues = otpFields.map((field) => field.current?.value || '').join('');
        return otpValues;
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>, otpFields: React.RefObject<HTMLInputElement>[]) => {
        if (e.key === 'Backspace' && index > 0 && !e.currentTarget.value) {
            otpFields[index - 1].current?.focus();
        }
    };

    const handlePaste = (index: any, e: any, otpFields: any) => {
        e.preventDefault();
        const pastedData = (e.clipboardData || window.Clipboard).getData('text');
        const values = pastedData.split('').slice(0, otpFields.length);

        values.forEach((value: any, i: any) => {
            if (otpFields[i] && otpFields[i].current) {
                otpFields[i].current.value = value;
                if (i < otpFields.length - 1) {
                    otpFields[i + 1].current?.focus();
                }
            }
        });
        const otpValues = otpFields.map((field: any) => field.current?.value || '').join('');
        return otpValues;
    };

    return { otpFields, handleChange, handleKeyDown, handlePaste };
};

const OTPInput = ({ onOTPChange, label, error }: any) => {
    const { otpFields, handleChange, handleKeyDown, handlePaste } = useOtpInput();

    const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const otpValue = handleChange(index, e, otpFields);
        onOTPChange(otpValue);
    };

    const handleInputKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        handleKeyDown(index, e, otpFields);
    };

    const handleInputPaste = (index: any, e: any) => {
        const otpValue = handlePaste(index, e, otpFields);
        onOTPChange(otpValue);
    };

    return (
        <div className="otp-input-container justify-content-center mb-2">
            <div className="form-group position-relative d-flex align-items-center justify-content-between d-sm-flex-dir-column">
                <label className='text-dark mb-0 me-2 d-sm-text-start d-sm-me-0 d-sm-mb-2 d-md-text-block flex-w-90'>{label}</label>
                <div className=' d-flex align-items-center justify-content-between w-100 mb-3 position-relative'>
                    {otpFields.map((inputRef, index) => (
                        <input
                            key={index}
                            ref={inputRef}
                            type="text"
                            inputMode='numeric'
                            pattern="\d*" // Enforce numeric input    
                            maxLength={1}
                            onChange={(e) => handleInputChange(index, e)}
                            onKeyDown={(e) => handleInputKeyDown(index, e)}
                            onPaste={(e) => handleInputPaste(index, e)}
                            className="form-control otp-field w-80px h-55px m-0 text-center fw-bold fs-md"
                        />
                    ))}
                    <div className='position-absolute start-0 bottom-0 mt-1 text-nowrap'>
                        {error && <label className="text-danger text-start ">{error}</label>}
                    </div>
                </div>


            </div>

        </div>
    );
};

export default OTPInput;
