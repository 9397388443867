import React, { useEffect, useState } from "react";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { Chart } from 'primereact/chart';
import { PiUsersThree } from "react-icons/pi";
import { BiFontSize, BiSolidUpvote, BiUpArrowAlt } from "react-icons/bi";
import { TbMessages } from "react-icons/tb";
import { GrResources } from "react-icons/gr";
import { GetDashboardWidgetCounts, GetTrendingResources, GetTrendingTopics, getUserGraphsData } from "../../services/AuthService";
import { formatDateInDayDateMonth, formatDates } from "../../utils/common/Format";
import { showToastError } from "../../utils/common/ToastMessages";
import { Dropdown } from "primereact/dropdown";
import { BsFilterLeft } from "react-icons/bs";

const Dashboard = () => {

    const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });
    const [todayDate, setTodayDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState<string>('1'); // Default to "This Week"
    const [trendingTopics, setTrendingTopics] = useState([])
    const [trendingResource, setTrendingResource] = useState([])
    const [widgtsCount, setWidgtsCount] = useState<any>()






    // Donought values
    const [chartDatas] = useState({
        labels: ['user Engagement', 'DAU', 'Bounce Rate'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ]
            }]
    });
    // Donought values
    const [lightOptions] = useState({
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true, // This will use point style for the legend
                    pointStyle: 'circle', // Set point style to circle
                    color: '#495057'
                }
            }
        }
    });
    // Graphs values Timeline Chart
    const [basicData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'First Dataset',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                borderColor: '##015852',
                tension: .4
            },
            {
                label: 'Second Dataset',
                data: [28, 48, 40, 19, 86, 27, 90],
                fill: false,
                borderColor: '#086861a1',
                tension: .4
            }
        ]
    });
    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {

            legend: {
                position: 'bottom',
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    // for montly graph users
    // for monthly graph users
    let horizontalOptions = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'oval',
                    color: '#000',
                    font: {
                        size: 14,
                        lineHeight: 1.5
                    }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#313131',
                    font: {
                        size: 12,
                        weight: 'bold'
                    },
                    stepSize: 1 // Ensure ticks are displayed at integer values
                },
                title: {
                    display: true,
                    padding: { top: 1 }
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };

    // For users Graphs get API integration 
    const getUsersGraphChart = () => {
        getUserGraphsData().then((response: any) => {
            setChartData({
                labels: response.userStatisticsData.map((data: any) => data.MonthName),
                datasets: [
                    {
                        label: 'Registered Users',
                        backgroundColor: '#015852',
                        data: response.userStatisticsData.map((data: any) => data.RegisteredUsers)
                    },
                    {
                        label: 'Unregistered Users',
                        backgroundColor: '#086861a1',
                        data: response.userStatisticsData.map((data: any) => data.UnregisteredUsers)
                    }
                ]
            });
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    // For users Graphs get API integration 
    const GetTrendingTopicsList = () => {
        GetTrendingTopics().then((response: any) => {
            setTrendingTopics(response?.userStatisticsData)
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    const GetDashboardWidgetCountsList = () => {
        GetDashboardWidgetCounts().then((response: any) => {
            setWidgtsCount(response?.widgetsCount[0])
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    const GetTrendingResourcesList = () => {
        GetTrendingResources().then((response: any) => {
            setTrendingResource(response?.userStatisticsData)
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    const [conversationCount, setConversationCount] = useState()
    const getUserConversationCount = async () => {
        try {
            const obj = {
                "SecureCode": "8y!X2$zH7n@Q"
            };
            const response = await fetch('https://flexapi.appgallery.ai/api/get_conversationv1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            });
            if (response) {
                const data = await response.json();
                setConversationCount(data.total_conversations);
            } else {
                showToastError('Error while fetching conversations count');
            }
        } catch (error) {
            // showToastError('Error while fetching conversations count');
        }
    };

    useEffect(() => {
        setTodayDate(new Date());
        getUsersGraphChart()
        GetTrendingTopicsList()
        GetTrendingResourcesList()
        GetDashboardWidgetCountsList()
        getUserConversationCount()

    }, [])
    const options = [
        { id: '0', label: 'Today' },
        { id: '1', label: 'This Week' },
        { id: '2', label: 'This Month' },
        { id: '3', label: 'This Year' }
    ];
    const handleChange = (event: any) => {
        const value = event.value;
        setSelectedOption(value);
    }

    return (
        <>
            <div className="container-fluid px-3">
                <div className="d-flex align-items-start justify-content-between">
                    <div>
                        <h2 className="fw-bold mb-0  leading-9" >Dashboard </h2>
                        <div className="mt-[-6px] d-flex justify-content-between">
                            <p className="fw-semibold text-base mb-0 text-primary my-0 p-0 leading-[16px]">
                                {formatDates(todayDate)}
                            </p>
                            <div className="d-flex justify-content-end mt-0 align-items-center">
                            </div>

                        </div>
                    </div>

                    {/* <div className="smart-dropdown position-relative">
                        <Dropdown value={selectedOption} options={options.map(option => ({ label: option.label, value: option.id }))} onChange={handleChange} />
                        <BsFilterLeft className="position-absolute start-0 top-50 translate-y-center text-gray ms-2" />
                    </div> */}
                </div>
                <div className="d-flex  mt-3 row d-flex">
                    <div className="col-md-3" >
                        <div className="bg-white rounded-md border shadow-md p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="fw-bold text-primary text-xl">{widgtsCount?.UsersCount}</h5>
                                <span className="w-[40px] h-[40px] bg-secondary bg-opacity-25 p-2 d-flex justify-content-center align-items-center rounded-circle"><PiUsersThree size={20} /></span>
                            </div>
                            <span className="mt-3 ">Number of Users</span>
                        </div>

                    </div>
                    <div className="col-md-3" >
                        <div className="bg-white rounded-md border shadow-md p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="fw-bold text-primary text-xl">{widgtsCount?.UpVotesCount}</h5>
                                <span className="w-[40px] h-[40px] bg-secondary bg-opacity-25 p-2 d-flex justify-content-center align-items-center rounded-circle"><BiSolidUpvote size={20} /></span>
                            </div>
                            <span className="mt-4 "> Number of Upvotes</span>
                        </div>
                    </div>
                    <div className="col-md-3" >
                        <div className="bg-white rounded-md border shadow-md p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="fw-bold text-primary text-xl">{conversationCount || 0}</h5>
                                <span className="w-[40px] h-[40px] bg-secondary bg-opacity-25 p-2 d-flex justify-content-center align-items-center rounded-circle"><TbMessages size={30} /></span>
                            </div>
                            <span className="mt-4 "> Number of Conversations</span>
                        </div>
                    </div>
                    <div className="col-md-3" >
                        <div className="bg-white rounded-md border shadow-md p-3">
                            <div className="d-flex justify-content-between">
                                <h5 className="fw-bold text-primary text-xl">{widgtsCount?.ResourcesCount}</h5>
                                <span className="w-[40px] h-[40px] bg-secondary bg-opacity-25 p-2 d-flex justify-content-center align-items-center rounded-circle"><GrResources size={20} /></span>
                            </div>
                            <span className="mt-4 "> Number of Resources</span>
                        </div>
                    </div>
                </div>
                <div className=" mt-4" style={{ display: 'none' }}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="bg-white p-3 shadow-md border rounded-md">
                                <div >
                                    <h5 className="fw-bold d-flex justify-content-center">Timeline Chart</h5>

                                    <Chart type="line" data={basicData} options={basicOptions} style={{ height: '295px', width: '800px' }} />
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4  bg-white p-3 shadow-md border rounded-md justify-content-end "   >
                            <h5 className="fw-bold d-flex justify-content-center">User Engagement</h5>
                            <div style={{ position: 'relative', width: '60%', margin: '0 auto' }}>
                                <Chart type="doughnut" data={chartData} options={lightOptions} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-3">
                    <div className="bg-white p-3 shadow-md border rounded-md">
                        {/* <h4 className="fw-bold d-flex mb-3">Users</h4>
                        <Chart type="bar" data={chartData} options={horizontalOptions} /> */}
                        <h4 className="fw-bold d-flex mb-3">Users</h4>
                        {chartData && chartData.datasets && chartData.datasets.length > 0 ? (
                            <Chart type="bar" data={chartData} options={horizontalOptions} />
                        ) : (
                            <div className="text-center text-gray h-calc-100vh-minus-669 d-flex justify-content-center align-items-center">No data found</div>
                        )}
                    </div>
                </div>
                <div className="my-3">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="bg-white p-3 shadow-md border rounded-md pb-0 mih-calc-411">
                                <div className="d-flex justify-content-between mb-4">
                                    <h4 className="fw-bold mb-0">Trending Topics</h4>

                                    <HiMiniArrowTrendingUp className="fw-bold text-secondary" size={20} />

                                </div>
                                <div className="h-calc-100vh-minus-516 overflow-y-auto overflow-x-hidden">
                                    {trendingTopics?.length > 0 ? trendingTopics && trendingTopics?.map((item: any, index: any) => (
                                        <div key={index} className="d-flex justify-content-between mb-[10px]">
                                            <h6 className="mb-0 text-capitalize"> {item?.TopicName}</h6>
                                            <p className="d-flex justify-content-between mb-0 fw-bold text-base"> <BiUpArrowAlt className="text-primary pt-0" size={20} /> {item?.TotalUpVotes}</p>
                                        </div>
                                    )) : <div className="text-center text-gray h-100 d-flex align-items-center justify-content-center">No data found</div>}
                                </div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="bg-white p-3 shadow-md border rounded-md pb-0 mih-calc-411">
                                <div className="d-flex justify-content-between mb-4">
                                    <h4 className="fw-bold mb-0 ">Trending Resources</h4>
                                    <HiMiniArrowTrendingUp className="fw-bold text-secondary" size={20} />
                                </div>
                                <div className="h-calc-100vh-minus-516 overflow-y-auto  overflow-x-hidden">
                                    {trendingResource?.length > 0 ? trendingResource && trendingResource?.map((item: any, index: any) => (
                                        <div key={index} className="d-flex justify-content-between mb-[10px]">
                                            <h6 className="mb-0 text-capitalize" title={item?.Title}>{item?.Title?.length > 80 ? item?.Title?.slice(0, 80) + '...' : item?.Title} </h6>
                                            <p className="d-flex justify-content-between mb-0 fw-bold text-base"> <BiUpArrowAlt className="text-primary pt-0" size={20} /> {item?.TotalUpVotes}</p>
                                        </div>
                                    )) : <div className="text-center text-gray h-100 d-flex align-items-center justify-content-center">No data found</div>}
                                </div>

                            </div>

                        </div>
                    </div>



                </div>

            </div>
        </>
    );
}

export default Dashboard;
