import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../redux/slice/AuthSlice';

export const store = configureStore({
  reducer: {
    userBucket: authReducer, // Assuming 'notes' should use the same reducer as 'userBucket'
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
