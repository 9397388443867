import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/ui/Input";
import { isNotEmptyString, isValidEmail, notAllowSpaces } from "../../utils/common/Validation";
import { ErrorMessages } from "../../constants/Labels";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { custumLogin } from "../../services/AuthService";
import logonew from '../../assets/images/logo.svg';
import { fetchImages } from "../../constants/HandleImages";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../redux/slice/AuthSlice";


const SignIn: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Handle change method
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
  };
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // To submit sign in form
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors: { [key: string]: string } = {};
    if (!isNotEmptyString(email)) {
      newErrors.email = ErrorMessages.EmailRequired;
    } else if (!isValidEmail(email)) {
      newErrors.email = ErrorMessages.InvalidEmail;
    }
    if (!isNotEmptyString(password)) {
      newErrors.password = ErrorMessages.passwordRequired;
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const object = {
        email: email,
        password: password,
      };
      custumLogin(object).then((response: any) => {
        if (response.status) {
          localStorage.setItem('authToken', response.token)
          localStorage.setItem("UserDetails", JSON.stringify(response.userdetails));
          navigate("/home");
          // navigate("/resources")
          showToastSuccess(response?.message)
          setLoading(false);
          dispatch(setUserDetails(response.userdetails));
        }
        else {
          setLoading(false);
          showToastError(response?.message)
        }
      }).catch(() => {
        showToastError(ErrorMessages.SomethingWrong);
        setLoading(false);
      })

    }
  };
  // TO navigate to forgot password
  const navToForgotPswrd = () => {
    navigate('/forgotPassword')
  };
  // TO avoid empty spaces cursor moving
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    notAllowSpaces(event.currentTarget.value, event);
  };
  // For password
  const handleKeyDowns = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
    } 
  };

  return (
    <>
      <div className="container mxw-100">
        <div className="row d-flex align-items-center justify-content-center">

          <div className="col-xl-5 col-lg-6 col-sm-12">
            <div className="vh-100 d-flex align-items-center justify-content-center w-100">
              <div className="inner w-100 shadow-sm p-4 border rounded-md bg-white">
                <div className="row">
                  <div>
                    <img src={logonew} className="d-flex mx-auto w-[120px] mb-[34px]"
                      alt="StratPilot" />
                    <h3 className="text-center font-weight-bold mb-0 fw-bold">
                      Welcome to Autistica Admin Portal!
                    </h3>
                    <p className="text-center mb-4 fs-md leading-tight " >
                      Sign In with your credentials
                    </p>
                  </div>
                  <form onSubmit={submitHandler} className="">
                    <div className="pb-3">
                      <InputField
                        name="email"
                        value={email}
                        label="Email address"
                        placeholder="example@gmail.com"
                        onChange={handleChange}
                        error={errors.email}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div className="form-group mb-0 position-relative input-pwd-pe-60 pb-2">
                      <InputField
                        name="password"
                        label="Password"
                        value={password}
                        placeholder="Enter your password here..."
                        onChange={handleChange}
                        error={errors.password}
                        type={showPassword ? "text" : "password"}
                        onKeyDown={handleKeyDowns}
                      />
                      <span
                        className="position-absolute  top-0 mt-[33px] end-[10px] cursor-pointer"
                        onClick={togglePasswordVisibility} >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>

                    </div>
                    <div className="text-primary font-size-12 fw-semibold mb-4 text-right">
                      <span
                        className="cursor-pointer fw-bold text-primary"
                        onClick={() => { navToForgotPswrd(); localStorage.setItem('userEmail', email) }} >
                        Forgot Password?
                      </span>
                    </div>

                    {/* <button
                      className="btn btn-primary w-100 d-flex mx-auto justify-content-center mb-3 text-white font-size-14 py-2 mt-3"
                      type="submit" disabled={loading} >
                      Sign In
                      {loading && (
                        <span className="loader " style={{ display: 'inline-block' }}>
                          <img src={fetchImages.loader} alt="Loading..." style={{ width: '20px', height: 'auto', marginLeft: '10px' }} className="text-dark" />
                        </span>
                      )}
                    </button> */}
                    {!loading ? <button
                      className="btn btn-primary w-100 d-flex mx-auto justify-content-center mb-3 text-white font-size-14 py-2 mt-3 h-[40px]"
                      type="submit" disabled={loading} >
                      <span className="h-[20px] d-inline-block mt-[2px]">Sign In</span>
                    </button> :
                      <button
                        className="btn btn-primary w-100 d-flex mx-auto justify-content-center mb-3 text-white font-size-14 py-2 mt-3 h-[40px]" disabled={loading}>
                        <span className="loader " style={{ display: 'inline-block' }}>
                          <img src={fetchImages.loader} alt="Loading..." style={{ width: '20px', height: 'auto', marginLeft: '10px' }} className="text-dark" />
                        </span>
                      </button>
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default SignIn;
