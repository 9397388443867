import React, { useCallback, useEffect, useRef, useState } from "react";
import { BiUpArrowAlt } from "react-icons/bi";
import { FaEllipsisV } from "react-icons/fa";
import CreateResources from "./CreateResources";
import { convertUtcToLocalDDMM, formatDateInMonthDateYear, formatDates, setDateFormat, } from "../../utils/common/Format";
import ViewResource from "./ViewResource";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { deleteReource, getResourceByID, getResources } from "../../services/ResourcesService";
import { ErrorMessages } from "../../constants/Labels";
import SearchInput from "../../components/ui/SearchInput";
import Pagination from '@mui/material/Pagination';
import { FiPlusCircle } from "react-icons/fi";
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useDispatch, useSelector } from "react-redux";
import { setShowSuccesPopup } from "../../redux/slice/AuthSlice";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import ResourceMetrics from "./ResourceMetrics";
import { PiArrowsDownUpFill } from "react-icons/pi";
import moment from 'moment';
import { BsFilterLeft } from "react-icons/bs";







export default function Resources() {
    // const [actionIndex, updateActivityIndex] = useState<number | null>(null);
    const [showCreateResouce, setShowCreateResouce] = useState<any>(false)
    const [resourceDetials, setshowResourceDetails] = useState(false)
    const [selectedItem, setSelectedItem] = useState<any>()
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [deletingIndex, setDeletingIndex] = useState<any>()
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [searchTerm, setSearchTerm] = useState('')
    const [startDate, setStartDate] = useState<any>('');
    const [endDate, setEndDate] = useState<any>('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [orderByQiscore, setOrderByQiscore] = useState<any>('Asc');
    const [orderByUpvotes, setOrderByUpvotes] = useState('Asc')
    const [orderByDate, setorderByDate] = useState('Asc')
    const [selectedOption, setSelectedOption] = useState<any>('');
    const dispatch = useDispatch()
    const [sortValue, setSortValue] = useState('')
    const [orderBy, setOrderBy] = useState<any>('Asc');
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const recordsPerPage = 10; // Number of records per page
    const [resourcesData, setResourcesData] = useState([])
    const [selectedUserList, setSelectedUserList] = useState('resourceLog')
    const [showEditInViewSc, setShowEditInViewSc] = useState(false)
    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [actionIndex, setActionIndex] = useState<number | null>(null);

    const showAddSuccessPopup = useSelector((state: any) => state?.userBucket?.showSuccespopup)

    const options = [
        { value: '0', label: 'All' },
        { value: '1', label: 'Popular' },
        { value: '2', label: 'ByDate' },
        { value: '3', label: 'Most Bookmarked' }
    ];

    // To clear activity popup
    const clearActivityPopup = useCallback(() => {
        setActionIndex(null);
    }, []);
    // handle clikoutside action popup
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (dropdownRefs.current.some(ref => ref && ref.contains(event.target as Node))) {
            return;
        }
        clearActivityPopup();
    }, [clearActivityPopup]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    // To open actions 
    const OpenActionArea = (index: number) => {
        setActionIndex(index);
    };

    // // Handling click outside dropdown
    // useEffect(() => {
    //     const cleanupClickOutside = handleClickOutside(dropdownRef, clearActivityPopup);
    //     return () => {
    //         cleanupClickOutside();
    //     };
    // }, []);
    // // Function to clear the activity popup
    // const clearActivityPopup = () => {
    //     updateActivityIndex(null);
    // }

    // Dropdown selections
    const [dates, setDate] = useState<any>()
    const handleSelect = (date: any) => {
        const startDate = moment(date.selection.startDate).startOf('day').format('YYYY-MM-DD');
        const endDate = moment(date.selection.endDate).startOf('day').format('YYYY-MM-DD');
        setDate(date)
        setStartDate(startDate);
        setEndDate(endDate);
    };
    const selectionRange = {
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
        key: 'selection',
    };
    const handleChange = (event: any) => {
        // const value = event.target.value;
        setPageNumber(1)
        setSearchTerm('')
        const value = event.label;
        if (value == 'ByDate') {
            setTimeout(() => {
                setShowCalendar(true)
                setSelectedOption(value)
                setStartDate(new Date())
                setEndDate(new Date())
            }, 500)

        } else {
            setSelectedOption(value);
            setShowCalendar(false)
            setStartDate('')
            setEndDate('')
        }
    };
    // custum selected dates submit
    const setCustumDates = () => {
        let SDates = moment(dates?.selection?.startDate).startOf('day').format('YYYY-MM-DD');
        let EDates = moment(dates?.selection?.endDate).startOf('day').format('YYYY-MM-DD');
        setStartDate(SDates);
        setEndDate(EDates);
        // setShowCalendar(false);
        getResurceListData();
        setShowCalendar(false)

    }
    // cancel the selected dates
    const resetTime = () => {
        setStartDate(" ")
        setEndDate(" ")
        setShowCalendar(false)
    }
    // open actions
    // const OpenActionArea = (selectedIndex: number) => {
    //     updateActivityIndex(selectedIndex);
    // };
    // To open delete resource popup
    const deleteItem = (resourceID: any) => {
        setDeletingIndex(resourceID)
        setShowDeletePopup(true)
        clearActivityPopup()
    }
    // Delete resource success handle
    const deleteSuccessHandle = () => {
        let obj = {
            "resourceId": deletingIndex
        }
        deleteReource(obj).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message)
                getResurceListData();
                setShowDeletePopup(false)
            } else {
                showToastSuccess(response.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
            setShowDeletePopup(false)
        })
    }
    // To add new resource
    const createResources = () => {
        setSelectedItem('')
        setShowCreateResouce(true)
        setSortValue('')
        setPageNumber(1)
        setSelectedOption('')
        setOrderBy('Asc')
        setSearchTerm('')
        setSelectedUserList('resourceLog')
        setShowCalendar(false)
    }
    // To veiw resource detials
    const viewResource = (item: any) => {
        clearActivityPopup()
        getResourceByID(item).then((response: any) => {
            setShowCreateResouce(false)
            setshowResourceDetails(true)
            setSelectedItem(response?.resourceDetails[0])
        }).catch((error: any) => {
            showToastError(ErrorMessages.SomethingWrong)
        })

    }
    // To open edit popup
    const editresourceItem = (item: any) => {
        clearActivityPopup()
        getResourceByID(item).then((response: any) => {
            if (response.status) {
                setShowCreateResouce(true)
                setSelectedItem(response.resourceDetails[0])
            } else {
                showToastError(response?.message)
            }

        }).catch((error: any) => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    // Searchfunctionality
    const handleSearch = (value: any) => {
        setSearchTerm(value);
        // setSortValue('')
    };

    useEffect(() => {
        // if (selectedOption !== 'ByDate') {
        getResurceListData();
        // }
    }, [pageNumber, searchTerm, orderBy, selectedOption]);
    // Pagination
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };

    const getResurceListData = () => {
        const utcOffset = getUTCOffset()
        let obj = {
            "pageNumber": pageNumber,
            "pageSize": 10,
            "search": searchTerm,
            'filterBy': selectedOption,
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "sortColumnName": sortValue,
            "sortColumnDirection": orderBy,
            "utcOffset": (startDate && endDate !== '' ? utcOffset : '')

        }
        getResources(obj).then((response: any) => {
            if (response?.status == true) {
                setResourcesData(response.resourceList[0].ResourceList)
                setTotalCount(response.resourceList[0].TotalCount);
            } else {
                showToastError(response?.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }

    const totalPages = Math.ceil(totalCount / recordsPerPage);
    // Qiscore/upvote order changing
    const [orderBytopicNames, setOrderBytopicNames] = useState('Asc')
    const [orderByusefulness, setOrderByusefulness] = useState('Asc')
    const [orderByFlaggedCount, setOrderByFlaggedCount] = useState('Asc')
    const [orderBytitle, setOrderBytitle] = useState('Asc')




    const toggleOrder = (type: 'qiscore' | 'upvotes' | 'date' | 'topicNames' | 'usefulness' | 'FlaggedCount' | 'title') => {
        if (type === 'qiscore') {
            const newOrder = orderByQiscore === 'Asc' ? 'Desc' : 'Asc';
            setOrderByQiscore(newOrder);
            setOrderBy(newOrder)
            setSortValue('qiScore')
        } else if (type === 'upvotes') {
            const newOrder = orderByUpvotes === 'Asc' ? 'Desc' : 'Asc';
            setOrderByUpvotes(newOrder);
            setSortValue('upvotesCount')
            setOrderBy(newOrder)
        }
        else if (type === 'date') {
            const newOrder = orderByDate === 'Asc' ? 'Desc' : 'Asc';
            setorderByDate(newOrder);
            setSortValue('uploadedDateTime')
            setOrderBy(newOrder)
        }
        else if (type === 'topicNames') {
            const newOrder = orderBytopicNames === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytopicNames(newOrder);
            setSortValue('topicNames')
            setOrderBy(newOrder)
        }
        else if (type === 'usefulness') {
            const newOrder = orderByusefulness === 'Asc' ? 'Desc' : 'Asc';
            setOrderByusefulness(newOrder);
            setSortValue('usefulness')
            setOrderBy(newOrder)
        }
        else if (type === 'FlaggedCount') {
            const newOrder = orderByFlaggedCount === 'Asc' ? 'Desc' : 'Asc';
            setOrderByFlaggedCount(newOrder);
            setSortValue('FlaggedCount')
            setOrderBy(newOrder)
        }
        else if (type === 'title') {
            const newOrder = orderBytitle === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytitle(newOrder);
            setSortValue('title')
            setOrderBy(newOrder)
        }
    };
    // To add custum filter to calender
    const customStaticRanges = createStaticRanges([
        ...defaultStaticRanges,
        {
            label: 'Custom',
            range: () => ({
                startDate: new Date(),
                endDate: new Date(),
            }),
            isSelected(range: any) {
                return range.custom;
            },
        },
    ]);
    const closeSucess = () => {
        dispatch(setShowSuccesPopup(false))

    }
    const listMethod = (listMethod: any) => {
        setSelectedUserList(listMethod)
    }

    const getUTCOffset = () => {
        const date = new Date();
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes <= 0 ? '+' : '-';
        return `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
    };


    return (
        <><div className="container-fluid px-3">
            {!showCreateResouce && !resourceDetials && !showEditInViewSc &&
                <div>
                    <div className="d-flex justify-content-between align-items-center shadow-sm bg-white rounded border p-3">
                        <h3 className="fw-bold mb-0">Resources </h3>
                        <button className="btn btn-primary text-white fs-sm align-items-center d-flex justify-content-between text-dark  py-2" onClick={createResources}><FiPlusCircle className="me-1" size={18} />
                            <div className="h-[16px] text-sm">Add Resource</div>
                        </button>
                    </div>

                    <div className="d-flex justify-content-between  my-3 bg-white rounded p-2 resourcelist-tab-panel">
                        <div className="d-flex justify-content-end mt-0 align-items-center">
                            {/* onClick={() => { listMethod('resourceLog'); setPageNumber(1); setSelectedOption(''); setSearchTerm('') }} */}
                            <div className={` p-3 fs-sm py-2 border text-center me-2 w-[160px]  rounded ${selectedUserList === 'resourceLog' ? 'bg-secondary text-white shadow-sm' : ' fw-normal'}`}> Resource Log</div>
                            {/* <div className={`p-3  fs-sm py-2 border text-center w-[160px] cursor-pointer rounded  ${selectedUserList === 'metrics' ? ' bg-secondary text-white shadow-sm' : ' fw-normal'}`} onClick={() => { listMethod('metrics'); setSortValue('') }} > Metrics</div> */}
                        </div>
                        {selectedUserList == 'resourceLog' && <div className="d-flex justify-content-end mt-0 align-items-center">
                            <div className="me-2">
                                <SearchInput label='Search' onChange={handleSearch} value={searchTerm} />
                            </div>
                            <div className="position-relative smart-dropdown">
                                <Dropdown options={options} onChange={handleChange} value={selectedOption} placeholder="Filter" />
                                <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />
                                <div style={{ zIndex: '99999' }} className="position-absolute  smart-date-picker deep-shadow bg-white p-0 rounded border mt-[6px]">
                                    {showCalendar && (
                                        <div className="" style={{ display: 'contents' }}>
                                            <DateRangePicker
                                                ranges={[selectionRange]}
                                                onChange={handleSelect}
                                                moveRangeOnFirstSelection={false}
                                                staticRanges={customStaticRanges}
                                                inputRanges={[]} // Remove predefined input ranges
                                                showMonthArrow={true} />
                                            <div className="d-flex justify-content-center position-absolute bottom-0 end-0 py-2  pr-3">
                                                <button className="btn btn-primary-outline py-1 h-[30px]" onClick={resetTime}><span className=" d-inline-block leading-6">Cancel</span></button>
                                                <button className="btn btn-primary text-white py-1  ms-2  h-[30px]" onClick={setCustumDates}><span className="d-inline-block  leading-6">Submit</span></button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>}

                    </div>
                    {selectedUserList == 'resourceLog' && (
                        <>
                            <div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-321">
                                <table className="table position-relative my-0">
                                    <thead>
                                        <tr className="fw-bold  sticky top-0 z-[9]">
                                            <th scope="col" className="w-[300px]">
                                                <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('title')} title={orderBytitle === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}>
                                                    <div className="d-flex align-items-center">Resources Title <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /> </div>
                                                </div>
                                            </th>

                                            <th scope="col">
                                                <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('qiscore')} title={orderByQiscore === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}>
                                                    <div className="d-flex align-items-center">QI-Score <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('upvotes')}>
                                                    <div className="d-flex align-items-center">Upvotes <span title={orderByUpvotes === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span></div>
                                                </div></th>
                                            <th className="w-[200px]" onClick={() => toggleOrder('topicNames')} title={orderBytopicNames === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center cursor-pointer text-nowrap">Topic <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                            <th onClick={() => toggleOrder('usefulness')} title={orderByusefulness === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center  cursor-pointer text-nowrap">UseFulness <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div> </th>
                                            <th onClick={() => toggleOrder('FlaggedCount')} title={orderByFlaggedCount === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center  cursor-pointer text-nowrap">Flagged <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                            <th className="text-nowrap">Uploaded By</th>

                                            <th scope="col"  className="w-[150px]"  ><div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('date')}>
                                                Date <span title={orderByDate === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span>
                                            </div></th>
                                            <th>Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resourcesData && resourcesData?.length > 0 ?
                                            resourcesData?.map((item: any, index: any) => (
                                                <tr key={item?.resourceId} className={`${resourcesData?.length == 1 ? 'moreoptions-position-fixed' : ''}`}>
                                                    <td className="cursor-pointer  hover:text-primary" onClick={() => viewResource(item.resourceId)} title={item?.title}>{item?.title?.length > 35 ? item?.title?.slice(0, 35) + '...' : item?.title}</td>
                                                    <td>{item?.qiScore}</td>
                                                    <td> <div className=" d-flex justify-content-start"><BiUpArrowAlt />{item?.upvotesCount}</div> </td>
                                                    {/* <td title={item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ')}>
                                                        {item?.topicNames?.slice(0, 2).map((topic: any) => topic?.TopicName).join(', ')}
                                                        {item?.topicNames?.length > 2 && '...'}
                                                    </td> */}
                                                    <td title={item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ')}>
                                                        {(() => {
                                                            const topics = item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ');
                                                            const maxLength = 20;
                                                            return topics.length > maxLength ? `${topics.slice(0, maxLength)}...` : topics;
                                                        })()}
                                                    </td>
                                                    <td>{item.usefulness} %</td>
                                                    <td>{item.FlaggedCount}</td>
                                                    <td title={item?.uploadedBy}>{item?.uploadedBy?.length > 10 ? item?.uploadedBy?.slice(0, 10) + '...' : item?.uploadedBy}</td>
                                                    <td>{formatDates(item.uploadedDateTime)}</td>
                                                    <td className="actions-column ">
                                                        <div className="d-flex justify-content-start align-items-center position-relative" ref={(el: any) => (dropdownRefs.current[index] = el)}>
                                                            <div className="ml-[20px] cursor-pointer">
                                                                <FaEllipsisV className="font-size-10 cursor-pointer" onClick={() => OpenActionArea(index)} />
                                                                <div className={`custom-dropdown-menu bg-white position-absolute border ml-[-108px] w-[120px] shadow-sm mt-[20px] top-0 z-[9] ${actionIndex === index ? '' : 'd-none'}`}>
                                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => viewResource(item.resourceId)}>View</div>
                                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => editresourceItem(item.resourceId)}>Edit</div>
                                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => deleteItem(item.resourceId)}>Delete</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6></td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end mt-2 ">
                                <div className="bg-white rounded p-2 shadow-sm z-[99] z-intial">
                                    <Pagination
                                        count={totalPages}
                                        variant="outlined"
                                        shape="rounded"
                                        page={pageNumber}
                                        onChange={handlePageChange}
                                        siblingCount={1} // Adjust as necessary
                                        boundaryCount={1} // Adjust as necessary
                                        showFirstButton
                                        showLastButton />
                                </div>
                            </div>

                        </>


                    )}

                    {selectedUserList == 'metrics' && (<ResourceMetrics />)}

                </div>}



            {/* {showAddSuccessPopup && <ResourceAddSuccess actionType='Success' title='Your Resource has been published Successfully.' closeModal={closeSucess} />} */}


        </div><div className="container-fluid px-3">
                {(showCreateResouce || showEditInViewSc) && <CreateResources setShowResource={setShowCreateResouce} selectedItem={selectedItem} setSelectedItem={setSelectedItem} getResurceListData={getResurceListData} setSearchTerm={setSearchTerm} setPageNumber={setPageNumber} setFilterValue={setSelectedOption} setShowEditResource={setShowCreateResouce} setshowResourceDetails={setshowResourceDetails} setShowEditInViewSc={setShowEditInViewSc} />}
                {resourceDetials && <ViewResource selectedItem={selectedItem} setSelectedItem={setSelectedItem} setshowResourceDetails={setshowResourceDetails} searchTerm={setSearchTerm} setPageNumber={setPageNumber} setFilterValue={setSelectedOption} getResurceListData={getResurceListData} setShowEditInViewSc={setShowEditInViewSc} />}
                {deletePopup && <DeleteConformation successHandle={deleteSuccessHandle} actionType='Delete' title='Delete Conformation' closeModal={setShowDeletePopup} />}
            </div></>
    )
}
