import { useState } from "react";
import TextareaField from "../../ui/TextArea";
import { isNotEmptyString, notAllowSpaces } from "../../../utils/common/Validation";
import { ErrorMessages } from "../../../constants/Labels";
import { deleteAppUser, deleteUser } from "../../../services/UserManagementService";
import { showToastError, showToastSuccess } from "../../../utils/common/ToastMessages";
import { getLoginUserID } from "../../../services/AuthService";
import { useNavigate } from "react-router-dom";

interface ConfirmationProp {
    actionType: string,
    title: string,
    closeModal: any,
    deleteIndex?: any,
    bodyText?: any,
    getUserListData?: any
    setShowDeletePopup?: any
    successHandle?: any
}

const DeleteConformation = ({ actionType, title, closeModal, successHandle, deleteIndex, bodyText, getUserListData, setShowDeletePopup }: ConfirmationProp) => {
    let headingText
    if (actionType == "Delete") {
        headingText = "Confirm Delete";
        bodyText = `Are you sure you want to delete?`
    }
    const [deleteReason, setdeleteReason] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        setdeleteReason(value);
    }

    // Delete resource success handle
    const deleteSuccessHandle = (e: any) => {
        e.preventDefault();
        const newErrors: { [key: string]: string } = {};
        if (!isNotEmptyString(deleteReason)) {
            newErrors.deleteReason = ErrorMessages.deleteReason;
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            let obj = {
                "userID": deleteIndex,
            }
            deleteAppUser(obj).then((response: any) => {
                if (response.status) {
                    showToastSuccess(response?.message)
                    getUserListData();
                    setShowDeletePopup(false)
                } else {
                    showToastSuccess(response?.message)
                }

            }).catch(() => {
                showToastError(ErrorMessages.SomethingWrong)
                setShowDeletePopup(false)
            })
        }
    }
    // TO avoid empty spaces cursor moving
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        notAllowSpaces(event.currentTarget.value, event);
    };

    return (
        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]  ">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className=" rounded bg-white  shadow-xxl border justify-content-center w-[400px]">
                    <div className='p-4'>

                        {actionType == 'DeleteUser' ?
                            <div>
                                <h4 className=' mb-4 text-start fw-bold'>Confirm Delete</h4>
                                <form onSubmit={deleteSuccessHandle}>
                                    <TextareaField
                                        name="deleteReason"
                                        label="Reason for deleting the user"
                                        componentName=""
                                        value={deleteReason}
                                        onChange={handleChange}
                                        rows={4}
                                        placeholder=""
                                        error={errors.deleteReason}
                                        onKeyDown={handleKeyDown} />
                                    <div className='d-flex justify-content-end align-items-center mt-[32px]'>
                                        <button type="button" className='btn btn-primary-outline  me-2 w-[105px]' onClick={() => closeModal(false)}><span className="h-[20px] d-block"> Cancel</span></button>
                                        <button type="submit" className='btn btn-primary  text-white w-[105px]'><span className="h-[20px] d-block">Delete</span></button>
                                    </div>
                                </form>
                            </div>
                            :
                            <div>
                                <h4 className=' mb-4 text-start fw-bold'>{headingText}</h4>
                                <p className='font-size-16  mb-0 text-start'>{bodyText}</p>
                                <div className='d-flex justify-content-end align-items-center mt-[32px]'>
                                    <button className='btn btn-primary-outline  me-2 w-[105px]' onClick={() => closeModal(false)}><span className="h-[20px] d-block">Cancel</span></button>
                                    <button className='btn btn-primary  text-white w-[105px]' onClick={successHandle}><span className="h-[20px] d-block">Delete</span></button>
                                    {/* <button className='btn btn-primary  text-white w-[105px]' onClick={isAppUser == 1 ? deleteSuccessHandle : successHandle}><span className="h-[20px] d-block">Delete</span></button> */}

                                </div>

                            </div>

                        }


                    </div>
                </div>
            </div >
        </div >
    )
}

export default DeleteConformation;