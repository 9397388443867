import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ProtectedRoutes: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isUserLogged, setIsUserLogged] = useState<boolean>(false);
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false); // New state to ensure auth check is done before rendering

  const checkUserAuth = () => {
    const UserToken = localStorage.getItem("authToken");
    if (!UserToken || UserToken === "undefined") {
      setIsUserLogged(false);
      return navigate('/signin');
    }
    setIsUserLogged(true);
    if (location.pathname === "/") {
      navigate("/home");
    }
  };

  useEffect(() => {
    checkUserAuth();
    setIsAuthChecked(true); // Auth check is done
  }, [location.pathname]);

  // Ensure auth check is done before rendering
  if (!isAuthChecked) {
    return null; // or a loader/spinner
  }

  return isUserLogged ? <>{children}</> : null;
};

export default ProtectedRoutes;
