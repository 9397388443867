import React, { useState } from 'react';
import PasswordStrengthPopup from '../common/modals/PasswordStrenthPopup';


interface InputFieldProps {
    name: string;
    value: string;
    placeholder: string;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    type?: any;
    passwordStrength?: number; // Add a prop for password strength
    disabled?: boolean;
    min?: any;
    max?: any;
    autocomplete?: string;
    onKeyDown?: any;
}

const InputField: React.FC<InputFieldProps> = ({
    name,
    value,
    placeholder,
    label,
    onChange,
    error,
    type = 'text',
    passwordStrength = 0, // Default password strength to 0
    disabled,
    min,
    max,
    autocomplete,
    onKeyDown
}) => {
    const [passwordSatisfiesCriteria, setPasswordSatisfiesCriteria] = useState<boolean>(false);

    // Function to check if password satisfies all criteria
    const passwordSatisfiesAllCriteria = (password: string) => {
        return (
            /(?=.*[A-Z])/.test(password) &&
            /(?=.*[a-z])/.test(password) &&
            /(?=.*\d)/.test(password) &&
            /(?=.*[!@#$%^&*()])/.test(password) &&
            password.length >= passwordStrength
        );
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setPasswordSatisfiesCriteria(passwordSatisfiesAllCriteria(newPassword));
        onChange(e);
    };

    return (
        <div>
            <div className="form-group position-relative pb-0 mb-0">
                <label className="text-dark">{label}</label>
                <input
                    type={type}
                    className="form-control mt-0 min-h-10"
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={handlePasswordChange}
                    disabled={disabled}
                    min={min}
                    max={max}
                    autoComplete={autocomplete}
                    onKeyDown={onKeyDown}
                />

                {/* Render PasswordStrengthPopup only if password satisfies criteria */}
                {passwordSatisfiesCriteria || passwordStrength > 0 && <PasswordStrengthPopup strength={passwordStrength} password={value} />}
                {error && (
                    <label className="text-danger position-absolute bottom--11 start-0 my-0 fs-xs">{error}</label>
                )}
            </div>
        </div>
    );
};

export default InputField;
