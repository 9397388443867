import axios from "axios";
import { httpService } from "./api/Api";



// To Add user
export const inviteUser = (obj: any) => {
    return httpService.post('User/InviteUser', obj);
}
// To update user
export const updateUser = (obj: any) => {
    return httpService.post('UserManagement/UpdateUserData', obj);
}
//Get users list
export const getUserList = (obj: any) => {
    return httpService.post('UserManagement/GetUserList', obj)
}
// To delete user'
export const deleteUser = (id: any) => {
    return httpService.post('UserManagement/DeleteUser', id);
}
// To delete App user'
export const deleteAppUser = (id: any) => {
    return httpService.post('User/DeleteAppUserAccount', id);
}
