import React, { useCallback, useEffect, useState } from 'react'
import { IoMdArrowDropleft } from 'react-icons/io'
import { RxCross2 } from 'react-icons/rx'


interface PopupProps {
    setShowQiScorePopup?: any
    QiScoreValues?: any

}

export default function QiScoreDetails({ setShowQiScorePopup, QiScoreValues }: PopupProps) {
    return (
        <div className="position-absolute  w-[200px] start-0 top-0 h-100 z-[99999]  mt-[-67px] ml-[47px] group hidden group-hover:block">
             <IoMdArrowDropleft size={24} className="text-dark position-absolute top-[55px] start-0 mt-[12px] ml-[-6px]" />
            <div className="d-flex align-items-center h-100 justify-content-center  p-2">
                <div className="rounded-md bg-white  shadow-xxl border justify-content-center w-[200px] shadow-lg">
                    {/* <div className='d-flex justify-content-end me-1 mt-1 '>
                        <RxCross2 onClick={() => setShowQiScorePopup(false)} size={20} className="cursor-pointer" />

                    </div> */}
                    <div className='p-2 pb-1'>
                        <div className="d-flex justify-content-between mb-2">
                            <span className="text-base fw-bold">{QiScoreValues[0]?.IndexName}</span> <span className="fw-bold text-primary text-base">{QiScoreValues[0]?.Score}</span>
                        </div>
                        {QiScoreValues && QiScoreValues?.length > 0 && QiScoreValues?.slice(1).map((item: any, index: any) => (
                            <div key={index} className="d-flex justify-content-between mb-1">
                                <span className="text-gray">{item?.IndexName}</span> <span className="fw-bold text-dark">{item?.Score}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div></div>



    )


}