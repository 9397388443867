import React, { useEffect, useRef, useState } from "react";
import { FaRegUser, FaRegUserCircle, FaUser } from "react-icons/fa";
import { Outlet, useNavigate } from "react-router-dom";
import { clearSession, getLoginUserID } from "../../services/AuthService";
import { MdOutlineLogout } from "react-icons/md";
import logonew from '../../assets/images/logo.svg';
import { handleClickOutside } from "../../utils/common/CommonUtility";
import { useDispatch, useSelector } from "react-redux";
import UpdateProfile from "../settings/UpdateProfile";
import UpdateUserPassword from "../settings/Settings";
import { BsPersonLock } from "react-icons/bs";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
export default function Header() {

  const dropdownSecondRef = useRef<HTMLDivElement>(null);
  const [secondShowDropDown, setSecondShowDropDown] = useState(false)
  const [actionIndex, updateActivityIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const [showUpdateProfile, setShowUpdateProfile] = useState(false)
  const [showUpdatePassword, setShowUpdatePassword] = useState(false)
  const userDetails = useSelector((item:any)=> item?.userBucket?.userDetails)


  const dropdownRef = useRef<HTMLDivElement>(null);
  // Handling click outside dropdown
  useEffect(() => {
    const cleanupClickOutside = handleClickOutside(dropdownRef, hideMenuOptions);
    return () => {
      cleanupClickOutside();
    };
  }, []);

  const hideMenuOptions = () => {
    setSecondShowDropDown(false)
  }
  const dispatch = useDispatch();

  // const openMenu = () => {
  //   updateSecondSideMenuEnable(!secondSideMenuEnable)
  // }
  const logout = () => {
    clearSession();
    localStorage.clear()
    navigate('../signin');
  }
  const toggleDropdown = () => {
    setSecondShowDropDown(!secondShowDropDown);
  };
  const loggedInUserName = getLoginUserID()

 
  return (
    <>
      <header className="header">
        <div className="logo">
          <img src="" />
        </div>
        <div className="user-icon">
          <FaUser className="img-fluid rounded-circle w-h-30 p-1 bg-dark text-white" />
        </div>
      </header>
      <section className="header-lg position-fixed top-0 start-0 bg-white z-[99] sharp-shadow w-100 ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 d-sm-px-0 px-3">
              <div className="d-flex justify-content-between align-items-center  ">
                <a href="#" className="text-decoration-none py-1">
                  <img
                    src={logonew}
                    alt="Autistica"
                    className="h-[70px] "
                  />

                </a>
                <div className="d-flex align-items-center px-0 h-[40px] bg-secondary-200 px-2 pe-1 rounded-md w-[180px] cursor-pointer" onClick={toggleDropdown}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <span className="fs-sm d-inline-block me-2 mt-1 text-truncate w-[107px]" title={loggedInUserName?.userName}>
          {loggedInUserName?.userName}
        </span>
        <div className="d-flex align-items-center" onClick={toggleDropdown}>
          <div className="w-[30px] h-[30px] bg-secondary bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center me-1">
            <FaRegUserCircle className="h-auto text-white" size={20} />
          </div>
          {/* {secondShowDropDown ? <IoMdArrowDropdown size={20} /> : <IoMdArrowDropup size={20} />} */}
        </div>
      </div>
      {/* ref={dropdownRef} */}
      {secondShowDropDown && (
        <div className="custom-dropdown show position-relative" ref={dropdownRef} >
          <div className="custom-dropdown-menu z-[99999] position-absolute bg-white top-[32px] start-[-182px] w-[200px] shadow-lg border rounded-md z-[99999]" onClick={(e) => e.stopPropagation()}>
            <div className="p-2">
              <div className="d-flex align-items-center p-2 mb-1 user-details br-4 cursor-pointer hover:bg-light-primary rounded" onClick={() => setShowUpdateProfile(true)}>
                <div className="w-[20px] flex-20 flex ">
                  <FaRegUser size={16} className="w-30px" />
                </div>
                <span className="d-flex ms-1 font-size-14 m-0 d-block font-weight-medium h-[16px]">
                  Update Profile
                </span>
              </div>
              <div className="d-flex align-items-center p-2 mb-1 user-details br-4 cursor-pointer hover:bg-light-primary rounded" onClick={() => setShowUpdatePassword(true)}>
                <div className="w-[20px] flex-20 flex ">
                  <BsPersonLock size={20} />
                </div>
                <span className="d-flex ms-1 font-size-14 m-0 d-block font-weight-medium h-[16px]">
                  Update Password
                </span>
              </div>
              <div className="d-flex align-item-center p-2 mb-0 user-details br-4 cursor-pointer hover:bg-light-primary rounded" onClick={() => logout()}>
                <div className="w-[20px] flex-20  flex">
                  <MdOutlineLogout size={20} className="w-30px" />
                </div>
                <span className="d-flex ms-1 font-size-14 m-0 d-block font-weight-medium h-[16px]">
                  Sign out
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
              </div>


            </div>
          </div>
        </div>
      </section >
      {showUpdateProfile && <UpdateProfile setShowUPdateProfile={setShowUpdateProfile} />
      }
      {showUpdatePassword && <UpdateUserPassword setShowUpdatePassword={setShowUpdatePassword} />}


    </>
  );
}
