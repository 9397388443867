import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import Dashboard from "../pages/dashboard/Dashboard";
import Metrics from "../pages/metrics/Metrics";
import Moderation from "../pages/moderation/Moderation";
import Resources from "../pages/resources/ResourcesList";
import Settings from "../pages/settings/Settings";
import UserManagement from "../pages/usermanagement/UserManagement";
import ProtectedRoutes from "./ProtectedRoutes";
import Header from "../pages/common/Header";
import SideMenu from "../pages/common/SideMenu";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/PasswordSetup";
import Contribute from "../pages/contribute/ContributeList";
import UpdateProfile from "../pages/settings/UpdateProfile";

const Layout = () => (
  <>
    <Header />
    <div className="app-container">
      <SideMenu />
      <main>
        <div className="inner-body w-calc-226px ms-[226px] mt-[81px]  overflow-y-auto mih-calc-100">
          <Outlet />
        </div>
      </main>
    </div>
  </>
);

const Navigation = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/signin") {
      localStorage.clear();
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/resetPassword/:token?" element={<ResetPassword />} />
      <Route
        path="/"
        element={
          <ProtectedRoutes>
            <Layout />
          </ProtectedRoutes>
        }
      >
        <Route path="home" element={<Dashboard />} />
        <Route path="metrics" element={<Metrics />} />
        <Route path="moderation" element={<Moderation />} />
        <Route path="resources" element={<Resources />} />
        <Route path="userManagement" element={<UserManagement />} />
        <Route path="contribute" element={<Contribute />} />
      </Route>
      <Route path="*" element={<Navigate to="/signin" replace />} />
    </Routes>
  );
};

const AppRouter = () => (
  <Router>
    <Navigation />
  </Router>
);

export default AppRouter;
