import React, { useCallback, useEffect, useRef, useState } from "react";
import InputField from "../../components/ui/Input";
import { RxCross2 } from "react-icons/rx";
import TextareaField from "../../components/ui/TextArea";
import { useDropzone } from "react-dropzone";
import { isNotEmptyString, notAllowSpaces } from "../../utils/common/Validation";
import { ErrorMessages } from "../../constants/Labels";
import { CreateResource, UpdateResourceById, deleteReourceFile, getLookUPData } from "../../services/ResourcesService";
import {
    showToastError,
    showToastSuccess,
} from "../../utils/common/ToastMessages";
import { setShowSuccesPopup } from "../../redux/slice/AuthSlice";
import { useDispatch } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import { getLoginUserID } from "../../services/AuthService"
import imageData from "../../assets/icons.json";
import IconsPopup from "../../components/common/modals/IconsPopup";
import categories from "../../assets/categories.json";
import QuillToolbar, { formats, modules } from "../../components/ui/QuillToolbar";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QiScoreDetails from "../../components/common/modals/QiScoreDetails";



interface PopupProps {
    setShowResource?: any;
    selectedItem?: any;
    setSelectedItem?: any;
    getResurceListData?: any;
    setSearchTerm?: any
    setPageNumber?: any
    setFilterValue?: any
    setShowEditResource?: any
    setshowResourceDetails?: any
    setShowEditInViewSc?: any
}
interface SensitiveContentTopic {
    SensitiveContentID: number;
    SensitiveContentName: string;
}

export default function CreateResources({
    setShowResource,
    selectedItem,
    setSelectedItem, setShowEditResource, setshowResourceDetails, setShowEditInViewSc,
    getResurceListData, setSearchTerm, setPageNumber, setFilterValue
}: PopupProps) {
    const dispatch = useDispatch();
    let userDetails = getLoginUserID();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [webLink, setWebLink] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [checkedWarnings, setCheckedWarnings] = useState<any>({});
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [Topicoptions, setTopicoptions] = useState<any>([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedTagItems, setselectedTagItems] = useState<any>([]);
    const [Tagptions, setTagptions] = useState<any>([]);
    const [inputTagValue, setInputTagValue] = useState("");
    const [sensitiveContentTopics, setSensitiveContentTopics] = useState<SensitiveContentTopic[]>([]);
    const [qiScore, setqiScore] = useState([]);
    const [reviews, setReviews] = useState<any>({});
    const [files, setFiles] = useState<File[]>([]);
    const [newFiles, setNewFiles] = useState<File[]>([]); // Newly added files
    // const urlPattern = /^(https?:\/\/)?([a-z\d.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/i;
    const urlPattern = /^(https?:\/\/)?([a-z\d.-]+)\.([a-z.]{2,6})([\/\w .~%+&=#?@()-]*)*\/?$/i;
    const [showPopup, setShowPopup] = useState(false)
    const [selectedImages, setSelectedImages] = useState<any>([]);




    // Get lookupdata
    useEffect(() => {
        lookupdata();
    }, []);

    const lookupdata = () => {
        getLookUPData()
            .then((response: any) => {
                setTagptions(response[0]?.Tags);
                setTopicoptions(response[0]?.Topics);
                setSensitiveContentTopics(response[0]?.SensitiveContents);
                setqiScore(response[0]?.QIIndexes);
            })
            .catch(() => {
                showToastError("Error while getting lookup data");
            });
    };

    // In edit mode to bind values to the form
    useEffect(() => {
        if (selectedItem) {
            setTitle(selectedItem.title);
            setDescription(selectedItem.description);
            setWebLink(selectedItem.link);

            const selectedTopics = Array.isArray(selectedItem?.topicNames)
                ? selectedItem?.topicNames
                : [];
            setSelectedItems(selectedTopics);

            const selectedTags = Array.isArray(selectedItem?.tagNames)
                ? selectedItem?.tagNames
                : [];
            setselectedTagItems(selectedTags);

            // Set the files from the selectedItem's media
            if (selectedItem && selectedItem.media) {
                // Initialize `files` and `newFiles` with IDs and names
                const files = selectedItem?.media.map((mediaItem: any) => ({
                    name: mediaItem.FileName, // File name for display
                    Id: mediaItem.ResourceLinkID, // ID for identification
                    Filepath: mediaItem.ResourceLink,// Full path for image source
                    RFilePath: mediaItem.ResourceLink,
                    IsNew: 0
                }));
                setFiles(files);
                setNewFiles(files);

                // Initialize `checkedImages` based on `files`
                setSelectedImages(files); // This should now reflect selected images
            } else {
                setFiles([]);
                setNewFiles([]);
                setSelectedImages([]); // Reset when no media
            }

            // Initialize checkedWarnings using the IDs from sensitiveWarnings
            const initialCheckedWarnings: { [key: string]: boolean } = {};
            if (Array.isArray(selectedItem?.sensitiveContentNames)) {
                selectedItem.sensitiveContentNames.forEach(
                    (content: { SensitiveContentName: string }) => {
                        initialCheckedWarnings[content.SensitiveContentName] = true;
                    }
                );
            }
            setCheckedWarnings(initialCheckedWarnings);
            // To bind reviews  Qi score
            const initialReviews: { [key: string]: number } = {};
            selectedItem.qiScore.forEach(
                (score: { IndexName: string; Score: number }) => {
                    initialReviews[score.IndexName] = score.Score;
                }
            );
            setReviews(initialReviews);
        }
    }, []);


    // Form handlechange
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        switch (name) {
            case "title":
                setTitle(value);
                break;
            // case "description":
            //     setDescription(value);
            //     break;
            case "webLink":
                setWebLink(value);
                break;
            default:
                break;
        }
    };
    const handleDescriptionChange = (
        value: string,
        delta: any,
        source: any,
        editor: any
    ) => {
        setDescription(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            description: "",
        }));
    };
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

    // Files drag and drop
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const filteredFiles = acceptedFiles.filter(file => validImageTypes.includes(file.type));
        if (filteredFiles.length !== acceptedFiles.length) {
            showToastError('You can able to upload only images.')
        }
        setFiles(prevFiles => [...prevFiles, ...filteredFiles]);

    }, []);

    // Handle file change
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(e.target.files || []);
        const filteredFiles = selectedFiles.filter(file => validImageTypes.includes(file.type));
        if (filteredFiles.length !== selectedFiles.length) {
            showToastError('You can able to upload only images.')
        }
        setFiles(prevFiles => [...prevFiles, ...filteredFiles]);

    };
    // To remove the uploaded file
    const [resourceIdsToDelete, setResourceIdsToDelete] = useState<any[]>([]);

    const removeFile = (index: number, resourceId: any) => {
        if (resourceId) {
            setResourceIdsToDelete((prevIds: any) => [...prevIds, resourceId]);
            setSelectedImages((prevFiles: any) => prevFiles.filter((_: any, i: number) => i !== index));
        } else if (selectedItem === '') {
            setSelectedImages((prevFiles: any) => prevFiles.filter((_: any, i: number) => i !== index));
        }
        else {
            setSelectedImages((prevFiles: any) => {
                const updatedFiles = prevFiles.filter((_: any, i: number) => i !== index);
                return updatedFiles;
            });
        }
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' as any });

    const handleImageSelection = (selectedImagesFromPopup: string[]) => {
        setSelectedImages(selectedImagesFromPopup); // Update the state with selected images
        setShowPopup(false);
    };

    // convert resources form data into form data payload
    const submitHandler = (e: any) => {
        e.preventDefault();
        const tagNames = selectedTagItems?.map((item: any) => item.TagName).join(",");
        const topicNames = selectedItems?.map((item: any) => item.TopicName).join(",");
        const sensitiveContentIds = Object.keys(checkedWarnings).filter((key) => checkedWarnings[key]).map((key) =>
            sensitiveContentTopics?.find(
                (topic: SensitiveContentTopic) => topic.SensitiveContentName === key
            )?.SensitiveContentID
        )
            .filter(Boolean);

        const reviewsPayload = qiScore?.map(({ QIIndexScoreID, IndexName }) => {
            const score = reviews[IndexName] ? parseInt(reviews[IndexName], 10) : 0;
            return {
                qiScoreID: QIIndexScoreID,
                score: isNaN(score) ? 0 : score,
            };
        });
        // Taken dummy object as above to validate minim score
        const reviewsminscore = qiScore?.map(({ QIIndexScoreID, IndexName, MinScore }) => {
            const score = reviews[IndexName] ? parseInt(reviews[IndexName], 10) : 0;
            return {
                qiScoreID: QIIndexScoreID,
                score: isNaN(score) ? 0 : score,
                MinScore: MinScore
            };
        });

        const newErrors: { [key: string]: string } = {};
        if (!isNotEmptyString(title)) {
            newErrors.title = ErrorMessages.TitleRequired;
        }
        if (selectedItems.length === 0) {
            newErrors.topic = ErrorMessages.TopicsRequired;
        }
        if (isNotEmptyString(webLink) && !urlPattern.test(webLink)) {
            newErrors.webLink = "Please enter a valid URL";
        }
         // if (!isNotEmptyString(description)) {
        //     newErrors.description = ErrorMessages.DecriptionRequired;
        // }
        // if (selectedTagItems.length === 0) {
        //     newErrors.tags = ErrorMessages.TagsRequired;
        // }
      
        // if (!isNotEmptyString(webLink)) {
        //     newErrors.webLink = ErrorMessages.WebLink;
        // }
       
        // if (selectedImages?.length === 0) {
        //     newErrors.files = ErrorMessages.FilesRequired;
        // }
        // if (sensitiveContentIds?.length === 0) {
        //     newErrors.SensitiveContent = ErrorMessages.SensitiveContent;
        // }
        // QI Score validation
        const invalidScores = reviewsminscore.filter(({ score, MinScore }) => score < MinScore);
        if (invalidScores.length > 0) {
            newErrors.qiScores = "Minimum review values are required.";
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length != 0) {
            showToastError("Please fill all the mandatory feilds")
        }
        // If there are no errors, creating payload
        if (Object.keys(newErrors).length === 0) {
            setLoading(true);
            if (selectedItem == '') {
                const formData = new FormData();
                formData.append("Title", title);
                formData.append("Description", description);
                formData.append("Link", webLink);
                formData.append("UploadedBy", userDetails?.userId);
                formData.append("SensitiveContentIds", sensitiveContentIds.length > 0 ? sensitiveContentIds.join(",") : '0');
                formData.append("TagNames", tagNames);
                formData.append("TopicNames", topicNames);
                // files.forEach((file: any) => {
                //     formData.append("files", file);
                // });

                const cleanedArray = selectedImages?.map((item: any) => {
                    const { RFilePath, IsNew, ...rest } = item;
                    return rest;
                });
                formData.append("files", JSON.stringify(cleanedArray));

                // const selectedImagesJson = JSON.stringify(selectedImages);

                // formData.append("files", selectedImagesJson);

                formData.append("QIScores", JSON.stringify(reviewsPayload));
                uploadResource(formData);
            } else {
                if (resourceIdsToDelete.length > 0) {
                    resourceIdsToDelete.map(resourceId => {
                        let obj = {
                            "ResourceLinkID": resourceId
                        };
                        return deleteReourceFile(obj).then((res: any) => {
                        })
                            .catch(error => {
                                showToastError("Failed to delete file but you can proceed with updating the resource.");
                            });
                    });
                }
                const formData = new FormData();
                formData.append("Title", title);
                formData.append("Description", description);
                formData.append("ResourceLink", webLink);
                formData.append("ModifiedBy", userDetails?.userId);
                formData.append("SensitiveContentNames", sensitiveContentIds.length > 0 ? sensitiveContentIds.join(",") : '0');
                formData.append("TagNames", tagNames);
                formData.append("TopicNames", topicNames);
                formData.append("ResourceId", selectedItem.resourceId);
                // formData.append("files", selectedImages);
                const uniqueFromSecondArray = selectedImages.filter((image: any) =>
                    !files.some((existingFile: any) => existingFile.Filepath === image.Filepath)
                );
                // Remove the `RFilePath` and `IsNew` keys from each object
                const cleanedArray = uniqueFromSecondArray.map((item: any) => {
                    const { RFilePath, IsNew, ...rest } = item;
                    return rest;
                });
                formData.append("files", JSON.stringify(cleanedArray));
                formData.append("QIScores", JSON.stringify(reviewsPayload));
                UpdateResource(formData)
            }
        }
    };


    // Api to upload resource
    const uploadResource = (formData: any) => {
        CreateResource(formData)
            .then((response: any) => {
                if (response.status == true) {
                    showToastSuccess(response.message);
                    setShowResource(false);
                    getResurceListData();
                    dispatch(setShowSuccesPopup(true));
                    setLoading(false);
                    setSearchTerm('')
                    setFilterValue('')
                    setPageNumber(1)
                    setShowEditResource(false)
                    return;
                }
                setLoading(false);
                showToastError(response.message);
                setSelectedImages([]);
            })
            .catch(() => {
                setLoading(false);
                showToastError("An error occurred while creating resource");
            });
    };
    // APi to update resources by id
    const UpdateResource = (formData: any) => {
        UpdateResourceById(formData).then((response: any) => {
            if (response.status == true) {
                showToastSuccess(response.message);
                setShowResource(false);
                getResurceListData();
                dispatch(setShowSuccesPopup(true));
                setLoading(false);
                setSearchTerm('')
                setFilterValue('')
                setPageNumber(1)
                setShowEditInViewSc(false)
                setShowEditResource(false)
                return;
            }
            setLoading(false);
            showToastError(response.message);
        })
            .catch(() => {
                setLoading(false);
                showToastError("An error occurred while creating resource");
            });
    };
    // Handle sensitive content change
    const handleSensitiveChange = (event: any) => {
        const { name, checked } = event.target;
        setCheckedWarnings((prevTopics: any) => ({
            ...prevTopics,
            [name]: checked,
        }));
    };
    // for resource Review points

    const handleReviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredValue = event.target.value;
        const maxValue = Number(event.target.max);
        const minValue = Number(event.target.min);
        // Check if the entered value is a valid number
        const numericValue = Number(enteredValue);
        if (isNaN(numericValue)) {
            showToastError('Please enter a valid value');
            event.target.value = '';
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [event.target.name]: ''
            }));
            return;
        }

        // Check if the entered value is an integer
        if (!Number.isInteger(numericValue)) {
            showToastError('Please enter valid Round off value');
            event.target.value = '';
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [event.target.name]: ''
            }));
            return;
        }

        // Check if the entered value is within the valid range
        if (numericValue > maxValue) {
            showToastError('Value cannot exceed the maximum score');
            event.target.value = '';
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [event.target.name]: ''
            }));
        } else if (numericValue < minValue) {
            showToastError('Value cannot be less than the minimum score');
            event.target.value = '';
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [event.target.name]: ''
            }));
        } else {
            const { name } = event.target;
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [name]: enteredValue,
            }));
        }
    };


    // Handlers for Tag and Topic changes
    const handleTagChanges = (e: any) => {
        const selectedTags = e.value.map((tagName: string) =>
            Tagptions?.find((tag: any) => tag?.TagName === tagName)
        );
        setselectedTagItems(selectedTags);
    };
    // Handle change to set selected topics
    const handleTopicChanges = (e: any) => {
        const selectedTopics = e.value.map((topicName: string) =>
            Topicoptions?.find((topic: any) => topic?.TopicName === topicName)
        );
        setSelectedItems(selectedTopics);
    };
    // To show newly added Topic
    const handleAddNewItem = (event: React.KeyboardEvent) => {
        if ((event.key === "Enter" || event.key === "Tab") && isNotEmptyString(inputValue)) {
            event.preventDefault(); // Prevent default behavior
            if (inputValue && !(Topicoptions ?? []).some((option: any) => option.TopicName === inputValue)) {
                const newTopic = { TopicId: " ", TopicName: inputValue };
                setTopicoptions([...(Topicoptions ?? []), newTopic]);
                setSelectedItems([...selectedItems, newTopic]);
                setInputValue("");
            }
        }
    };
    // To show newly added tag
    const handleAddNewTag = (event: React.KeyboardEvent) => {
        if ((event.key === "Enter" || event.key === "Tab") && isNotEmptyString(inputTagValue)) {
            event.preventDefault(); // Prevent default behavior
            if (
                inputTagValue &&
                !(Tagptions ?? []).some((option: any) => option.TagName === inputTagValue)
            ) {
                const newTag = { TagId: " ", TagName: inputTagValue };
                setTagptions([...(Tagptions ?? []), newTag]);
                setselectedTagItems([...selectedTagItems, newTag]);
                setInputTagValue("");
            }
        }
    };
    // Template to add new topics
    const headerTemplate = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleAddNewItem}
                    placeholder="Type and press enter to add new topic"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };
    // Template to add new tags
    const headerTemplateTag = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    value={inputTagValue}
                    onChange={(e) => setInputTagValue(e.target.value)}
                    onKeyDown={handleAddNewTag}
                    placeholder="Type and press enter to add new tags"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };
    // TO avoid empty spaces cursor moving
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        notAllowSpaces(event.currentTarget.value, event);
    };
    const selectedItemsTooltip = useRef(null);

    const openIconPopup = () => {
        setShowPopup(true)
    }

    const closePopup = () => {
        setShowPopup(false);
    };
    //   const [showPopup, setShowPopup] = useState(false);
    const cancelClick = () => {
        setShowResource(false);
        // setSelectedItem();
        setShowEditResource(false)
        if (selectedItem !== '') {
            setShowEditResource(false)
            setshowResourceDetails(false)
            setShowEditInViewSc(false)
        }
    }
    const [QiScorePopup, setShowQiScorePopup] = useState(false)


    return (
        <div className="mb-2">
            <div className="bg-white rounded shadow-sm p-3 h-100 overflow-y-auto ">
                <div className="d-flex justify-content-start">
                    {selectedItem == "" ? (
                        <h3 className="fw-bold">Add Resources</h3>
                    ) : (
                        <h3 className="fw-bold">Update Resources</h3>
                    )}
                </div>
                <form onSubmit={submitHandler}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="position-relative mb-3 h-49">
                                <InputField
                                    name="title"
                                    label="Title"
                                    value={title}
                                    placeholder="Enter your Resource Title here "
                                    onChange={handleChange}
                                    error={errors.title}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className="form-group position-relative mb-3 ">
                                <label className="form-group ">Add Topic</label>
                                <div className="smart-multiselect position-relative group">
                                    <MultiSelect
                                        value={selectedItems?.map((item: any) => item?.TopicName)}
                                        options={Topicoptions?.map((option: any) => ({
                                            label: option?.TopicName,
                                            value: option?.TopicName,
                                        }))}
                                        onChange={handleTopicChanges}
                                        filter
                                        display="chip"
                                        panelHeaderTemplate={headerTemplate}
                                        style={{ width: "100%" }}
                                        placeholder="Select Topics"
                                        // tooltip={selectedItems.length >= 4 && selectedItems?.map((item: any) => item?.TopicName).join(', ')} // Tooltip content
                                        tooltipOptions={{ event: 'hover', position: 'bottom' }} // Tooltip options
                                        ref={selectedItemsTooltip}
                                    />
                                  {/* <QiScoreDetails setShowQiScorePopup={setShowQiScorePopup} QiScoreValues={selectedItems} /> */}

                                    {errors.topic && (
                                        <div className="error text-danger mt-5">{errors?.topic}</div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group position-relative mb-3">
                                <label className="form-group ">Add Tag</label>
                                <div className="smart-multiselect">
                                    <MultiSelect
                                        value={selectedTagItems?.map((item: any) => item?.TagName)}
                                        options={Tagptions?.map((option: any) => ({
                                            label: option?.TagName,
                                            value: option?.TagName,
                                        }))}
                                        onChange={handleTagChanges}
                                        filter
                                        display="chip"
                                        panelHeaderTemplate={headerTemplateTag}
                                        style={{ width: "100%" }}
                                        placeholder="Select Tags"
                                        // tooltip={selectedTagItems.length >= 4 && selectedTagItems?.map((item: any) => item?.TagName).join(', ')} // Tooltip content
                                        tooltipOptions={{ event: 'hover', position: 'bottom' }} // Tooltip options
                                        ref={selectedItemsTooltip}
                                    />
                                    {errors.tags && (
                                        <div className="error text-danger mt-1">{errors.tags}</div>
                                    )}
                                </div>
                            </div>
                            <div className="position-relative mb-3">
                                <InputField
                                    name="webLink"
                                    label="Add Link"
                                    value={webLink}
                                    placeholder="Web link "
                                    onChange={handleChange}
                                    error={errors.webLink}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="position-relative mb-0 ">
                                <div className="form-group position-relative mb-3 textarea-h-273">
                                    <label className='text-dark'>Description</label>

                                    {/* <TextareaField
                                        name="description"
                                        label="Description"
                                        componentName=""
                                        value={description}
                                        onChange={handleChange}
                                        placeholder="Enter your Description here"
                                        error={errors.description}
                                        onKeyDown={handleKeyDown}

                                    /> */}
                                    <QuillToolbar />
                                    <div className="" >
                                        <ReactQuill
                                            value={description}
                                            onChange={handleDescriptionChange}
                                            placeholder= "Enter your Description here"
                                            modules={modules}
                                            formats={formats}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">

                        </div>
                        <div className="col-md-12">

                        </div>
                        <div className="col-md-12">
                            {/* <div className="form-group position-relative mb-2">
                                <label className="form-group ">Upload a File</label>
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: "2px dashed #cccccc",
                                        borderRadius: "4px",
                                        padding: "20px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                        background: "white",
                                    }}
                                >
                                    {isDragActive ? (
                                        <p className="my-1 text-gray">Drop the Images here ...</p>
                                    ) : (
                                        <p className="my-1 text-gray">Drag & Drop your Images here</p>
                                    )}
                                    <span>Or</span>
                                    <div className="mt-2">
                                        <button className="btn btn-primary text-white" type="button">
                                            Browse Files
                                            <input {...getInputProps()} accept="image/*" type="file" />

                                        </button>
                                    </div>
                                    <div className="text-danger position-absolute start-0 text-xs mt-4">
                                        {" "}
                                        {errors.files}
                                    </div>
                                </div>
                                {files && files.length > 0 && <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h4 className="d-flex justify-content-start fw-bold">Uploaded Files</h4>
                                        <div className="border px-1 pt-2 pb-0 rounded-md  mxh-160 overflow-y-auto">
                                            <div className="row mx-0">
                                                {files.length > 0 && files?.map((file: any, index: any) => (
                                                    <div
                                                        key={index}
                                                        className="col-md-3 mb-2 px-1"
                                                    >
                                                        <div className="bg-whitesmoke rounded-md p-2 d-flex align-items-center justify-content-between shadow-md border">
                                                            <span className="text-truncket text-sm fw-bold text-truncate">{file.name}</span>
                                                            <div className="w-[18px] h-[18px] flex-18  max-w-[18px] bg-secondary bg-opacity-50 rounded-circle d-flex align-items-center justify-content-center">
                                                                <RxCross2
                                                                    className=""
                                                                    size={12}
                                                                    onClick={() => removeFile(index, file.Id)}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            </div> */}
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="d-flex justify-content-start fw-bold mb-0">Select Icons</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-2">

                                    <div className="rounded-md p-3 border-dotted-solidate h-[80px]" onClick={openIconPopup}>
                                        <div className="form-group position-relative mb-0 d-flex justify-content-center h-100 align-items-center">
                                            <button className="btn btn-primary px-4 text-white" type="button" >Select Icons</button>
                                        </div>
                                    </div>
                                    <div className="text-danger text-xs ">
                                        {errors.files}
                                    </div>
                                </div>



                                {selectedImages && selectedImages.length > 0 &&

                                    <div className="col-md-12">
                                        {selectedImages?.length > 0 && <div className="text-lg d-flex justify-content-start font-semibold align-items-center ">Selected Icons: <span className="font-semibold text-primary ps-1 text-lg d-inline-block">{selectedImages?.length || selectedItem?.media?.length}</span></div>
                                        }
                                        <div className="border px-1 pt-2 pb-0 rounded-md  max-h-[200px] overflow-y-auto">
                                            <div className="row mx-0">
                                                {selectedImages.length > 0 && selectedImages?.map((file: any, index: any) => (
                                                    <div
                                                        key={index}
                                                        className="col-md-1 mb-2 px-1"
                                                    >
                                                        <div className="rounded-md p-2 d-flex flex-column  shadow-md border">
                                                            <div className="d-flex justify-content-end mb-2">
                                                                <div className="w-[16px] h-[16px] flex-16  max-w-[18px] bg-secondary bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center">

                                                                    <RxCross2
                                                                        className=""
                                                                        size={10}
                                                                        onClick={() => removeFile(index, file.Id)}
                                                                        style={{ cursor: "pointer" }}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <img src={file?.RFilePath} alt="images" className="w-[25px] h-[25px] object-contain d-flex  mx-auto" />
                                                            {/* {file?.IsNew == 0 && */}

                                                            {/* } */}

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>





                        </div>
                        <div className="col-md-12">
                            <div
                                className="position-relative mb-2 mt-3 p-3 border rounded "

                            >
                                <h4 className="d-flex justify-content-start fw-bold mb-0">
                                    {" "}
                                    Sensitive Warning{" "}
                                </h4>
                                <p className="mb-2 text-gray">
                                    Would you consider this to be a sensitive topic and likely cause
                                    upset? If so we'd appreciate if you let others know.{" "}
                                </p>
                                <div className="d-flex p-2 row">
                                    {sensitiveContentTopics?.map((topic: any) => (
                                        <div className="col-md-3 mb-2 px-1">
                                            <label key={topic.SensitiveContentID} className="bg-white border shadow-sm me-0 rounded d-flex align-items-center p-[12px]">
                                                <input
                                                    className="text-dark form-check-input w-h-20 cursor-pointer mt-0 "
                                                    type="checkbox"
                                                    name={topic.SensitiveContentName}
                                                    onChange={handleSensitiveChange}
                                                    checked={
                                                        checkedWarnings[topic.SensitiveContentName] || false
                                                    } // Use topic.name for checkedWarnings
                                                />{" "}
                                                <div className="fs-sm ms-1 h-[16px]"> {topic.SensitiveContentName}</div>
                                            </label>
                                        </div>
                                    ))}
                                    {errors.SensitiveContent && (
                                        <div className="error text-danger bottom-minus-18 ps-0">{errors.SensitiveContent}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="position-relative mb-1 mt-3 p-3 rounded border">
                                <h4 className="d-flex justify-content-start fw-bold mb-3">Review</h4>
                                <div className="max-h-48 overflow-y-auto overflow-x-hidden">
                                    <div className="w-calc-3px h-100">
                                        <div className="mb-2 d-flex row">
                                            {qiScore && qiScore?.map(({ IndexName, MaxScore, MinScore }) => (
                                                <div className="col-md-3 mb-2">
                                                    <label className="m-0 border p-2 w-100 rounded bg-solitude">
                                                        <div className="w-100 d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center me-1">
                                                                <span className="d-inline-block me-1">{IndexName}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center ">
                                                                <div className="d-flex justify-content-between text-sm"><span>Min<span className="fw-bold text-dark me-0 text-primary">-{MinScore}</span></span><span className="mx-1 d-inline-block">,</span><span>Max<span className="fw-bold text-dark">-{MaxScore}</span></span></div>
                                                                <input
                                                                    type="number"
                                                                    name={IndexName}
                                                                    min={MinScore}
                                                                    max={MaxScore}
                                                                    onChange={handleReviewChange}
                                                                    value={reviews[IndexName] || ""}
                                                                    style={{ marginLeft: "0px", minWidth: "60px" }}
                                                                    className="ms-2 text-center fs-sm fw-bold input-number p-2 pt-6 form-control h-[30px] w-[120px]"
                                                                    onKeyDown={handleKeyDown}
                                                                />{" "}
                                                                {/* / <div className="fs-sm fw-bold">{MaxScore}</div> */}
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            ))}
                                            {errors.qiScores && (
                                                <div className="error text-danger bottom-minus-18 ps-0 mt-2">{errors.qiScores}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                            <button
                                className="btn btn-primary-outline me-2 px-4"
                                type="button"
                                onClick={cancelClick}
                            >
                                Cancel
                            </button>
                            {selectedItem == "" ? (
                                <button className="btn btn-primary px-4 text-white" type="submit" disabled={loading}>
                                    Submit
                                    {/* {loading && (
                                        <span className="loader " style={{ display: 'inline-block' }}>
                                            <img src={fetchImages.loader} alt="Loading..." style={{ width: '18px', height: 'auto', marginLeft: '10px' }} className="text-dark" />
                                        </span>
                                    )} */}
                                </button>
                            ) : (
                                <button className="btn btn-primary px-4 text-white" type="submit" disabled={loading}>
                                    Update
                                    {/* {loading && (
                                        <span className="loader " style={{ display: 'inline-block' }}>
                                            <img src={fetchImages.loader} alt="Loading..." style={{ width: '18px', height: 'auto', marginLeft: '10px' }} className="text-dark" />
                                        </span>
                                    )} */}
                                </button>
                            )}


                        </div>
                    </div>
                </form>
            </div>
            {showPopup && <IconsPopup images={imageData} onClose={closePopup} handleSubmit={handleImageSelection} categories={categories} selectedImages={selectedImages}
            />}
        </div>
    );
}
