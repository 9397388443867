
import React, { useEffect, useRef, useState } from "react";
import { BiUpArrowAlt } from "react-icons/bi";
import { FaEllipsisV } from "react-icons/fa";
import { handleClickOutside } from "../../utils/common/CommonUtility";
import { AiOutlineCalendar } from "react-icons/ai";
import { setDateFormat } from "../../utils/common/Format";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import ViewContributeTip from "./ViewContributeTip";
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { getResources } from "../../services/ResourcesService";
import { ErrorMessages } from "../../constants/Labels";
import Pagination from '@mui/material/Pagination';


export default function Contribute() {
    const [resourcesData, setResourcesData] = useState([
        {
            Title: " Title 1",
            topic: "Anxeity",
            resourcesDescription: "Awarness",
            date: "2024-01-29T05:28:09.63",
            qiScore: 4,
            upVotes: 3,
            status: 1,
            submittedby: 'Jondeo'
        },
        {
            Title: " Title 2",
            topic: "Anxeity",
            resourcesDescription: "Awarness",
            date: "2024-01-29T05:25:09.63",
            qiScore: 4,
            upVotes: 6,
            status: 1,
            submittedby: 'Jondeo'
        },
        {
            Title: " Title 3",
            topic: "Anxeity",
            resourcesDescription: "Diagnosis",
            date: "2024-02-29T05:25:09.63",
            qiScore: 4,
            upVotes: 0,
            status: 2,
            submittedby: 'Jondeo'
        },
        {
            Title: " Title 4",
            topic: "Anxeity",
            resourcesDescription: "Diagnosis",
            date: "2024-01-29T05:25:09.63",
            qiScore: 4,
            upVotes: 7,
            status: 2,
            submittedby: 'Jondeo'
        },
        {
            Title: " Title 5",
            topic: "Anxeity",
            resourcesDescription: "Awarness",
            date: "2024-01-29T05:25:09.63",
            qiScore: 4,
            upVotes: 7,
            status: 1,
            submittedby: 'Jondeo'
        }
    ]);
    const [actionIndex, updateActivityIndex] = useState<number | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [deleteItem, setDeleteItem] = useState()
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [tipDetials, setshowTipDetails] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [pageNumber, setPageNumber] = useState(1);




    // Handling click outside dropdown
    useEffect(() => {
        const cleanupClickOutside = handleClickOutside(dropdownRef, clearActivityPopup);
        return () => {
            cleanupClickOutside();
        };
    }, []);
    // Function to clear the activity popup
    const clearActivityPopup = () => {
        updateActivityIndex(null);
    }
    const OpenActionArea = (selectedIndex: number) => {
        updateActivityIndex(selectedIndex);
    };
    const deleteConform = (item: any) => {
        setDeleteItem(item)
        setShowDeletePopup(true)
    }
    const successHandle = () => {
        setShowDeletePopup(false)
        showToastSuccess('Log deleted successfully')

    }
    const viewTip = (item: any) => {
        setshowTipDetails(true)
        setSelectedItem(item)
    }
    const getContributeListData = (pageNumber: number, filterBy: any, selectedCategory: any) => {
        let obj = {
            "pageNumber": pageNumber,
            "pageSize": 10,
            "status": true,
            'filterBy': filterBy,
            "category": selectedCategory
        }
        getResources(obj).then((response: any) => {
            setResourcesData(response)
        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    const [selectedOption, setSelectedOption] = useState<any>('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dates, setDate] = useState<any>()
    const [selectedCategory, setSelectedCategory] = useState()
    const options = [
        { value: '0', label: 'popular' },
        { value: '1', label: 'Most Bookmarked' },
        { value: '2', label: 'By Date' },
        { value: '3', label: 'Awaiting Approval' },
        { value: '3', label: 'Flagged' }

    ];
    const categoryOptions = [
        { value: '0', label: 'Awarness' },
        { value: '1', label: 'Awarness' },
        { value: '2', label: 'Advocacy' },
        { value: '3', label: 'Diagnosis' },
    ];
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    }
    // To add custum filter to calender
    const customStaticRanges = createStaticRanges([
        ...defaultStaticRanges,
        {
            label: 'Custom',
            range: () => ({
                startDate: new Date(),
                endDate: new Date(),
            }),
            isSelected(range: any) {
                return range.custom;
            },
        },
    ]);

    // custum selected dates submit
    const setCustumDates = () => {
        setStartDate(dates?.selection?.startDate);
        setEndDate(dates?.selection?.endDate);
        // setShowCalendar(false);
        getContributeListData(pageNumber, dates, selectedCategory);
    }
    // cancel the selected dates
    const resetTime = () => {
        setStartDate(new Date())
        setEndDate(new Date())
        setShowCalendar(false)
    }
    const handleSelect = (date: any) => {
        setDate(date)
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
    };
    const handleChange = (event: any) => {
        // const value = event.target.value;
        const value = event.value;

        if (value == '2') {
            setShowCalendar(true)
            setSelectedOption(value)
            setStartDate(new Date())
            setEndDate(new Date())
        } else {
            setSelectedOption(value);
            setShowCalendar(false)
        }
    };
    const handleChangeCategory = (event: any) => {
        const value = event.value;
        setSelectedCategory(value)
    }
    useEffect(() => {
        if (selectedOption !== '2') {
            getContributeListData(pageNumber, selectedOption, selectedCategory);
        }
    }, [pageNumber, selectedOption, selectedCategory]);
    // Pagination
    const handlePageChange = (event: any, value: any) => {
        setPageNumber(value);
    };

    return (
        <><div className="container-fluid ">
            <div className="d-flex justify-content-between">
                <h4 className="fw-bold">Contribute </h4>
            </div>
            <div className="d-flex justify-content-between mt-2 mb-4">
                <h5 className="fw-semi-bold mt-3">Tips Log </h5>

                <div className="d-flex justify-content-between mt-3">
                    <div className="me-3">
                        <Dropdown options={categoryOptions} onChange={handleChangeCategory} value={selectedOption} placeholder="Category" />

                    </div>
                    <div>
                        <Dropdown options={options} onChange={handleChange} value={selectedOption} placeholder="Filter" />

                    </div>
                    <div style={{ zIndex: '99999' }} className="position-absolute mt-5">
                        {showCalendar && (
                            <div className="" style={{ display: 'contents' }}>
                                <DateRangePicker
                                    ranges={[selectionRange]}
                                    onChange={handleSelect}
                                    moveRangeOnFirstSelection={false}
                                    staticRanges={customStaticRanges}
                                    inputRanges={[]} // Remove predefined input ranges
                                    showMonthArrow={true}
                                />
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-dark text-white btn-sm" onClick={resetTime}>Cancel</button>
                                    <button className="btn btn-primary text-white btn-sm ms-2" onClick={setCustumDates}>Submit</button>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>

            <table className="table  position-relative ">
                <thead>
                    <tr className="fw-bold">
                        <td> Title </td>
                        <td> Topic </td>
                        <td>Submitted by </td>
                        <td>UpVotes</td>
                        <td>Status </td>
                        <td>Date </td>
                        <td>Action </td>
                    </tr>
                </thead>
                <tbody>
                    {resourcesData.map((item: any, index: any) => (
                        <tr key={index}>
                            <td>{item.Title}</td>
                            <td>{item.topic}</td>
                            <td>{item.submittedby}</td>
                            <td className=" d-flex justify-content-start"> <BiUpArrowAlt />{item.upVotes} </td>
                            <td>{item.status == 1 ? <button className="btn btn-success btn-sm"> Aprroved</button> : <button className="btn btn-dark btn-md" style={{ backgroundColor: 'lightgrey' }}>Pending</button>}</td>
                            <td>{setDateFormat(item.date)}</td>
                            <td>
                                <div className="d-flex justify-content-between align-items-center" ref={dropdownRef}>
                                    <FaEllipsisV className="font-size-10 cursor-pointer" onClick={() => OpenActionArea(index)} />
                                    <div className={`custom-dropdown-menu position-absolute border ${actionIndex === index ? '' : 'd-none'}`} style={{ marginLeft: "15px", backgroundColor: 'lightgray' }}>
                                        <div className="dropdown-item cursor-pointer" onClick={viewTip}>View</div>
                                        <div className="dropdown-item cursor-pointer">Edit</div>
                                        <div className="dropdown-item cursor-pointer">Approve</div>
                                        <div className="dropdown-item cursor-pointer my-1 py-" onClick={() => deleteConform(item)}>Delete</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="d-flex justify-content-center">
                <Pagination
                    count={10}
                    variant="outlined"
                    shape="rounded"
                    page={pageNumber}
                    onChange={handlePageChange}
                />
            </div>
        </div>


            {tipDetials && <ViewContributeTip selectedItem={selectedItem} setshowTipDetails={setshowTipDetails} />}
            {deletePopup && <DeleteConformation actionType="Delete" title="Delete Conformation" closeModal={setShowDeletePopup} successHandle={successHandle} />}

        </>


    )
}